import * as Mui from "@material-ui/core";

import React, { useEffect } from "react";

import { ApiResult } from "../../../Core/Models";
import MaterialTable from "material-table";
import { RoleDTO } from "../../Models";
import RoleViewModel from "../../ViewModels/RoleViewModel";
// App
import styled from "styled-components";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "../../../Core/Utils";

const roleViewModel = new RoleViewModel();

export const Container = styled(Mui.Box)`
    .MuiTableCell-root {
        font-size: 0.8rem;
    }
    .MuiTableSortLabel-root {
        :hover {
            color: lightgray;
        }
    }
    .MuiTableRow-root {
        cursor: pointer;
        :hover {
            background-color: lightgray;
        }
    }
`;

export const RoleListContainer: React.FC = () => {
    const { history } = useRouter();
    useEffect(() => {
        const response = roleViewModel.loadRolesAsync().then((apiResult: ApiResult<RoleDTO[]>) => {
            if (apiResult.wasSuccessful) {
            }
        });
    }, []);

    const handleRowClick = (e: any, rowData: any) => {
        history.push(`/roleslist/role/${rowData.id}`);
    };

    const handleNewClick = () => {
        history.push(`/roleslist/role/0`);
    };

    return useObserver(() => (
        <Container>
            <Mui.Box marginTop="45px" width="100%">
                <Mui.Typography variant="h1" color="textPrimary">
                    Roles
                </Mui.Typography>
                <Mui.Box paddingRight="10px">
                    <Mui.Box flexGrow={1} textAlign="right">
                        <Mui.Button variant="contained" color="secondary" onClick={() => handleNewClick()}>
                            <Mui.Typography variant="button">New Role</Mui.Typography>
                        </Mui.Button>
                    </Mui.Box>
                </Mui.Box>
            </Mui.Box>
            {
                <MaterialTable
                    columns={[{ title: "Name", field: "name" }]}
                    options={{
                        pageSize: 50,
                        pageSizeOptions: [10, 20, 50, 100],
                        emptyRowsWhenPaging: false,
                    }}
                    data={roleViewModel.getRoles}
                    onRowClick={handleRowClick}
                    title=""
                />
            }
        </Container>
    ));
};
