import { createMuiTheme, Theme, ThemeOptions } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { colors } from "@material-ui/core";

export const pxToRem = (value: number) => {
    return `${value / 16}rem`;
};
const coreBreakpointValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};
export const coreBreakpoints = createBreakpoints({ values: coreBreakpointValues });
export const coreTheme = createMuiTheme({
    coreBreakpoints,
    // app: {
    // 	colors: {
    // 		success: {
    // 			dark: colors.green[600],
    // 			light: colors.green[300],
    // 		},
    // 	},
    // },
    spacing: (value: number) => {
        let spacing = 8; //Default spacing on desktop
        const screenWidth = document.body.clientWidth;
        if (screenWidth <= coreTheme.breakpoints.values["md"]) spacing /= 2;
        if (screenWidth > coreTheme.breakpoints.values["md"] && screenWidth <= coreTheme.breakpoints.values["lg"]) spacing /= 1.5;
        if (screenWidth > coreTheme.breakpoints.values["lg"]) spacing /= 1;
        return value * spacing;
    },
    typography: {
        fontFamily: `"Nexa","NexaBold", sans-serif`,
        fontSize: 16,
        h1: {
            fontSize: pxToRem(96),
            [coreBreakpoints.down("sm")]: {
                fontSize: pxToRem(56),
            },
        },
        h2: {
            fontSize: pxToRem(60),
            [coreBreakpoints.down("sm")]: {
                fontSize: pxToRem(38),
            },
        },
        h3: {
            fontSize: pxToRem(48),
            [coreBreakpoints.down("sm")]: {
                fontSize: pxToRem(32),
            },
        },
        h4: {
            fontSize: pxToRem(32),
            [coreBreakpoints.down("sm")]: {
                fontSize: pxToRem(24),
            },
        },
        h5: {
            fontSize: pxToRem(20),
            [coreBreakpoints.down("sm")]: {
                fontSize: pxToRem(18),
            },
        },
        h6: {
            fontSize: pxToRem(18),
            [coreBreakpoints.down("sm")]: {
                fontSize: pxToRem(16),
            },
        },
        subtitle1: {
            fontSize: pxToRem(16),
            [coreBreakpoints.down("sm")]: {
                fontSize: pxToRem(14),
            },
        },
        subtitle2: {
            fontSize: pxToRem(14),
            [coreBreakpoints.down("sm")]: {
                fontSize: pxToRem(10),
            },
        },
        body1: {
            fontSize: pxToRem(16),
            [coreBreakpoints.down("sm")]: {
                fontSize: pxToRem(14),
            },
        },
        body2: {
            fontSize: pxToRem(14),
            [coreBreakpoints.down("sm")]: {
                fontSize: pxToRem(10),
            },
        },
        button: {
            fontSize: pxToRem(16),
            [coreBreakpoints.down("sm")]: {
                fontSize: pxToRem(14),
            },
        },
        caption: {
            fontSize: pxToRem(14),
            [coreBreakpoints.down("sm")]: {
                fontSize: pxToRem(10),
            },
        },
    },
    palette: {
        common: { black: "#000", white: "#fff" },
        background: { paper: "#fff", default: "#fafafa" },
        primary: { light: "#7986cb", main: "#3f51b5", dark: "#303f9f", contrastText: "#fff" },
        secondary: { light: "#ff4081", main: "#f50057", dark: "#c51162", contrastText: "#fff" },
        error: { light: "#e57373", main: "#f44336", dark: "#d32f2f", contrastText: "#fff" },
        text: {
            primary: "rgba(0,0,0, 1)",
            secondary: "rgba(255,255,255, 0.54)",
            disabled: "rgba(255,255,255, 0.38)",
            hint: "rgba(255,255,255, 0.38)",
        },
    },
    overrides: {
        MuiFormHelperText: {
            root: {
                color: "black",
            },
        },
        /*MuiFormLabel: {
            root: {
                color: "black",
            },
        },*/
    },
} as ThemeOptions);
//(window as any).theme = defaultTheme;
