export interface EventArgs {
    eventCategory: string;
    eventAction: string;
    eventLabel?: string;
    eventValue?: number;
}
//export { EventArgs, FieldsObject, TrackerNames };

// GoogleAnalytics.initialize([
// 	{
// 		trackingId: "UA-1852243-71", //UA-1852243-67
// 		gaOptions: {
// 			name: "PPEExchange",
// 		},
// 	},
// ]);
export const Init = (UA: string) => {
    /*    GoogleAnalytics.initialize(UA);
    GoogleAnalytics.set({ anonymizeIp: true });*/
};

///
/// sendEvent
///
///https://developers.google.com/analytics/devguides/collection/analyticsjs/events
export const sendEvent = (args: EventArgs) => {
    //let eventArgs: EventArgs = {
    //    category: category,
    //    action: action,
    //    label: label,
    //    nonInteraction: nonInteraction,
    //    transport: transport,
    //    value: value
    //};
    //if (args != undefined && args.transport == undefined) {
    // `args.transport` must be either one of these values: `beacon`, `xhr` or `image`
    //    args.transport = window.location.pathname;
    //}

    //GoogleAnalytics.event(args);
    (window as any).ga("gtag_UA_82073010_2.send", "event", args);
};

///
/// set
///
export const set = (path: string, title: string) => {
    (window as any).ga("set", "page", path);
};

///
/// pageview
///
export const pageview = (path: string, title?: string): void => {
    //GoogleAnalytics.pageview(path);
    /*(window as any).dataLayer.push({
        event: "pageview",
        page: {
            path: path,
            title: title,
        },
    });*/
    (window as any).ga("gtag_UA_82073010_2.send", {
        hitType: "pageview",
        page: path,
    });
};
