import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsInt, IsNotEmpty, ValidateNested } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class TestModel extends ModelBase<TestModel, TestModel> {
    @MobX.observable
    @IsNotEmpty({ message: "You must give me your name!!" })
    public firstName: string = "";
    @MobX.observable
    @IsNotEmpty({ message: "You must give me your name!!" })
    public lastName: string = "";
    @MobX.observable
    @IsInt({ message: "It must be a valid number" })
    public age: number = 0;
    @MobX.observable
    @IsNotEmpty({ message: "You must give me your username!!" })
    public userName: string = "";
    @MobX.observable
    public memo: string = "";
    @MobX.observable
    @ValidateNested({ each: true })
    public address: AddressModel = new AddressModel();

    //fromDto is required but you can leave it blank
    fromDto(model: TestModel): void {}

    //toDto is required but you can leave it blank
    toDto(model: TestModel): void {}
}

export class AddressModel extends ModelBase<AddressModel, AddressModel> {
    @MobX.observable
    @IsNotEmpty({ message: "You must add and address" })
    public addressLine1: string = "";
    @MobX.observable
    @IsNotEmpty({ message: "You must add a postcode" })
    public postCode: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: AddressModel): void {}

    //toDto is required but you can leave it blank
    toDto(model: AddressModel): void {}
}
