import moment from "moment";

export const dateTimeFormat = "DD/MM/YYYY";
export const FormatMoment = (momentDateTime: moment.Moment, format: string) => {
    if (!momentDateTime || !momentDateTime.isValid()) {
        return "";
    }

    return momentDateTime.format(format);
};
export const FormatMomentString = (rawDateTime: string | undefined, format: string) => {
    if (!rawDateTime) {
        return "";
    }

    const momentDateTime = moment.utc(rawDateTime);

    return FormatMoment(momentDateTime, format);
};

export const dateTimeString = (rawDateTime: string | undefined) => FormatMomentString(rawDateTime, dateTimeFormat);
