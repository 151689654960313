// Libraries
import React, { useContext } from "react";
import { useMediaQuery } from "Core/Utils/Media";
import { Link } from "@material-ui/core";
import styled from "styled-components";

// Components
import { Terms } from "../Components/Terms";

// Styling & images
import { GridSpaced } from "../StylesAppSpecific/GridHelperStyling";

export const TermsAndConditions: React.FC = () => {
    window.scrollTo(0, 0);
    const isMobile: boolean = useMediaQuery("screen and (max-width: 767px)") ? true : false;

    const terms = (
        <>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto"}
                padding={isMobile ? "0px 0px 40px 0px" : "0px 0px 70px 0px"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <TermsContainer item xs={12} sm={12}>
                    <Terms />
                </TermsContainer>
            </GridSpaced>
        </>
    );

    return (
        <>
            <div>{terms}</div>
        </>
    );
};

const TermsContainer = styled(GridSpaced)`
    p {
        font-size: 14px;
        margin-bottom: 15px;
        letter-spacing: -0.35px;
        line-height: 24px;
    }

    h1 {
        text-align: left;
        font-size: 28px;
    }

    h2 {
        text-align: left;
        font-size: 24px;
    }

    ol li {
        margin-bottom: 15px;
    }
`;
