import { Box, Button, Fade, Link, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Centered } from "Core/Components";
import { EditableInput } from "Core/Components/EditableInput";
import { ForgotPasswordModel } from "../Models";
import { ResetPasswordModel } from "../Models/ResetPasswordModel";
import { ResetPasswordViewModel } from "../ViewModels/ResetPasswordViewModel";
import { runInAction } from "mobx";
import { useObserver, useRouter } from "../../Core/Base";
import { RegisterUserViewModel } from "../ViewModels/RegisterUserViewModel";
import mainLogo from "../Content/mainLogoImage.svg";
import { StaticBGImg } from "../../Core/Components/Styles";
import Image34 from "../Content/Image 34.jpg";
import styled from "styled-components";

const ResetButton = styled(Button)`
    color: white;
    font-size: 18px;
    width: 300px;
    margin-top: 20px;
`;

export const ResetPasswordPage: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new ResetPasswordViewModel());
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        viewModel.getTokenFromUrl();
    }, []);

    const doSubmit = async (e: any) => {
        e.preventDefault();

        if (viewModel.isModelValid()) {
            runInAction(() => {
                setIsLoading(true);
            });

            const apiResult = await viewModel.resetPasswordAsync();

            runInAction(() => {
                setIsLoading(false);

                if (apiResult) {
                    if (apiResult.wasSuccessful) {
                        setErrorMessage("");
                        setIsSuccess(true);
                    } else {
                        setErrorMessage("* " + apiResult.errors[0].message);
                        setIsSuccess(false);
                    }
                } else {
                    setErrorMessage("* Unknown error has occurred.");
                    setIsSuccess(false);
                }
            });
        }
    };

    const goToForgotPassword = async (e: any) => {
        viewModel.history.push("/forgotpassword");
    };

    return useObserver(() => (
        <Fade in timeout={1000}>
            <Box marginLeft={viewModel.isMobile ? "0" : "15%"} width="300px" display="flex" justifyContent="center">
                <Box padding="5px">
                    {<img src={mainLogo} alt="" className="mainLogo" />}
                    <StaticBGImg alt={""} src={Image34} />
                    {viewModel.tokenValid === true ? (
                        <>
                            <Box justifyContent="center" textAlign="center" display="grid" minWidth="300px" maxWidth="300px">
                                <Typography component="h1" variant="h3" style={{ marginBottom: "25px", marginTop: "25px" }}>
                                    Reset password
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Please type a new password.
                                </Typography>
                            </Box>
                            <form onSubmit={doSubmit}>
                                {errorMessage !== "" && (
                                    <Typography variant="caption" style={{ color: "red" }}>
                                        {errorMessage}
                                    </Typography>
                                )}
                                <EditableInput<ResetPasswordModel>
                                    type="password"
                                    shrink={true}
                                    label="New Password"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="newPassword"
                                />
                                <EditableInput<ResetPasswordModel>
                                    type="password"
                                    shrink={true}
                                    label="Confirm Password"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="confirmPassword"
                                />
                                <ResetButton type="submit" fullWidth variant="contained" color="primary">
                                    {isLoading ? "Please Wait..." : "Reset password"}
                                </ResetButton>
                                {errorMessage !== "" && <Link onClick={goToForgotPassword}>Return to forgot password</Link>}
                                {isSuccess && (
                                    <Typography variant="h6" color="secondary">
                                        Successfully Reset Password
                                    </Typography>
                                )}
                            </form>
                        </>
                    ) : viewModel.tokenValid === false ? (
                        <>
                            <Box justifyContent="center" textAlign="center" display="grid" minWidth="300px" maxWidth="300px">
                                <Typography component="h1" variant="h3" style={{ marginBottom: "25px", marginTop: "25px" }}>
                                    Reset password
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Url is expired or invalid, please try again.
                                </Typography>
                                <Link onClick={goToForgotPassword}>Return to forgot password</Link>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box justifyContent="center" textAlign="center" display="grid" minWidth="300px" maxWidth="300px">
                                <Typography component="h1" variant="h3" style={{ marginBottom: "25px", marginTop: "25px" }}>
                                    Reset password
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Loading...
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Fade>
    ));
};
