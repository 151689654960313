import React, { useState, useContext } from "react";

import Toolbar from "@material-ui/core/Toolbar";

import Typography from "@material-ui/core/Typography";

import { Button, Drawer, AppBar, List, Box, Menu, MenuItem } from "@material-ui/core";

import { useObserver } from "mobx-react-lite";
import { StoresContext, Stores, StoresInstance } from "Custom/Stores";
import sharmansLogo from "../Content/sharmanslogo.svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { getHistory } from "../../Core/Utils/Utils";

export const Header: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const storesContext = useContext<Stores>(StoresContext);

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return useObserver(() => (
        <>
            <AppBar>
                <Toolbar>
                    {/* <IconButton color="inherit" aria-label="Open drawer" onClick={handleDrawerOpen} edge="start">
            <MenuIcon />
          </IconButton> */}
                    <Box flexGrow={1}>
                        <Box display={"flex"}>
                            <Box style={{ display: "pointer" }}>
                                <img onClick={() => getHistory().push("/")} src={sharmansLogo} alt="ShootHill" className="logoShootHill" />
                            </Box>
                            <Box display={"flex"} alignSelf={"center"}>
                                <Box display={"flex"} alignItems={"center"} paddingLeft={"30px"} marginLeft={"30px"} height={"40px"} borderLeft={"1px solid black"}>
                                    <Typography variant={"body2"} color={"textPrimary"}>
                                        CPD learning tool{StoresInstance.domain.AccountStore.isInRole("admin") ? <span style={{ color: "red" }}> Admin</span> : <></>}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {storesContext.domain.AccountStore.IsLoggedIn ? (
                        <Box>
                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} endIcon={<ArrowDropDownIcon />}>
                                {StoresInstance.domain.AccountStore.DisplayName}
                            </Button>
                            <Menu style={{ width: "200px" }} id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                                <MenuItem
                                    onClick={() => {
                                        StoresInstance.domain.AccountStore.Logout(true);
                                    }}
                                >
                                    Logout
                                </MenuItem>
                            </Menu>
                        </Box>
                    ) : (
                        <>
                            {/*<Box display={"flex"} alignItems={"center"} marginRight={"30px"} height={"40px"} borderLeft={"1px solid black"} />
                            <Box paddingTop={"10px"}>
                                <Button color="primary">
                                    <Link color={"primary"} component={RouterLink} to="login">
                                        <PhoneIcon style={{ color: "red" }} />
                                    </Link>
                                </Button>
                            </Box>
                            <Box paddingTop={"10px"}>
                                <Button color="primary">
                                    <Link color={"primary"} component={RouterLink} to="samples">
                                        <SearchIcon style={{ color: "black" }} fontWeight={700} />
                                    </Link>
                                </Button>
                            </Box>*/}
                        </>
                    )}
                </Toolbar>
            </AppBar>
            {/* <Drawer variant="persistent" anchor="left" open={open}>
        <div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer> */}
        </>
    ));
};
