import React from "react";
import { Header } from "../Header";
import { Box, Container, Typography } from "@material-ui/core";
import { CSSGrid, Cell } from "Core/Components/Styles";
import { AutoGrid } from "Core/Components/AutoGrid";

//EN: use empty generic type to get default react props
export const DefaultLayout: React.FC<{}> = (props) => {
    return (
        <>
            <CSSGrid columns={"1fr"} rows={"0px"} gap={"0px"} areas={["header", "content"]}>
                <Cell area={"header"}>
                    <Header />
                </Cell>
                <Cell marginTop={"100px"} area={"content"} id={"content"}>
                    <div>{props.children}</div>
                </Cell>
            </CSSGrid>
        </>
    );
};
