import React, { useEffect, useState } from "react";
import { GridContainerAdmin } from "Custom/StylesAppSpecific/AdminStyling";
import Typography from "@material-ui/core/Typography";
import { AppBar, Box, Tab, Tabs, Button } from "@material-ui/core";
import MaterialTable from "material-table";
import { AdminHomeViewModel } from "../../ViewModels/AdminHomeViewModel";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";
import { defaultTheme } from "../../StylesAppSpecific/AppStyling";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { TypographyX } from "Core/Components/Styles";
import { AutoGrid } from "../../../Core/Components/AutoGrid";
import PlaneSVG from "Custom/Content/PlaneIcon.svg";
import DeleteSVG from "Custom/Content/DeleteIcon.svg";
import CreateIcon from "@material-ui/icons/Create";
import { FormatMomentString } from "../../../Core/Utils";
import GetAppIcon from "@material-ui/icons/GetApp";
import { RegisterPage } from "../RegisterPage";
import { StoresInstance } from "../../Stores";

const ApprovalButton = styled(Button)`
    color: ${defaultTheme.palette.primary.dark};
    padding: 0;
`;

const BlueButton = styled(Button)`
    color: #337ab7;
    padding-left: 10px;
`;

const RedButton = styled(Button)`
    color: #b42025;
    padding-left: 10px;
`;

const StyledTable = styled(MaterialTable)``;

export const AdminHome: React.FC = () => {
    const [viewModel] = useState(() => new AdminHomeViewModel());
    const [refresh, setRefresh] = React.useState(true);
    const [value, setValue] = React.useState(0);
    const handleChange = (event: any, newValue: number) => {
        if (newValue == 1) {
            StoresInstance.coreStore.PageView("approved");
            viewModel.getApprovedUsers();
        } else if (newValue == 0) {
            StoresInstance.coreStore.PageView("pending");
        }
        setValue(newValue);
    };

    useEffect(() => {
        async function func() {
            await viewModel.getPendingUsers();
            //setRefresh(!refresh);
        }
        // Execute the created function directly
        func();
    }, []);

    const pendingBox = () => {
        return useObserver(() => (
            <Box>
                <Box>
                    <MaterialTable
                        options={{
                            headerStyle: {
                                backgroundColor: "#EDEDED",
                                color: "#000",
                                fontWeight: "bolder",
                            },
                        }}
                        style={{ padding: "0 10px 0 10px", border: "1px solid none" }}
                        title={
                            <TypographyX fontWeight={"700"} variant={"h5"}>
                                Pending
                            </TypographyX>
                        }
                        columns={[
                            {
                                title: "Name",
                                field: "firstName",
                                render: (rowData) => {
                                    return `${rowData.firstName} ${rowData.lastName}`;
                                },
                            },
                            {
                                title: "Company Name",
                                field: "companyName",
                            },
                            { title: "Email address", field: "email" },
                            {
                                title: <Box marginRight={"-25px"}>Registered</Box>,
                                headerStyle: {
                                    textAlign: "center",
                                },
                                field: "createdAt",
                                render: (rowData) => <Box textAlign={"center"}>{FormatMomentString(rowData.createdAt.toString(), "DD MMM YYYY")}</Box>,
                            },

                            {
                                title: "",
                                field: "approved",
                                render: (rowData) => (
                                    <Box display={"flex"} flexDirection={"rows"} justifyContent={"center"} alignItems={"center"}>
                                        <CheckCircleIcon color={"primary"} style={{ marginRight: "10px" }} />
                                        <ApprovalButton onClick={() => viewModel.approveUser(rowData)} data-cy="approvebutton1">
                                            Approve User
                                        </ApprovalButton>
                                    </Box>
                                ),
                            },
                        ]}
                        isLoading={viewModel.IsLoading}
                        //data={[...viewModel.pendingUsers, viewModel.pendingUsers]}
                        data={viewModel.pendingUsers.filter((a) => !a.approved)}
                    />
                </Box>
            </Box>
        ));
    };

    const approvedBox = () => {
        return useObserver(() => (
            <Box>
                <Box>
                    <MaterialTable
                        onRowClick={(event, rowData, togglePanel) => {
                            StoresInstance.domain.UserDetailId = rowData!.id;
                            setValue(2);
                        }}
                        options={{
                            headerStyle: {
                                backgroundColor: "#EDEDED",
                                color: "#000",
                                fontWeight: "bolder",
                            },
                            pageSize: 50,
                            pageSizeOptions: [5, 10, 50],
                        }}
                        style={{ padding: "0 10px 0 10px", border: "1px solid none" }}
                        title={
                            <TypographyX fontWeight={"700"} variant={"h5"}>
                                Pending
                            </TypographyX>
                        }
                        columns={[
                            { title: "Email address", field: "email" },
                            {
                                title: <Box marginRight={"-25px"}>Registered</Box>,
                                headerStyle: {
                                    textAlign: "center",
                                },
                                field: "createdAt",
                                render: (rowData) => <Box textAlign={"center"}>{FormatMomentString(rowData.createdAt.toString(), "DD MMM YYYY")}</Box>,
                            },
                            {
                                title: <Box marginRight={"-25px"}>Last login</Box>,
                                headerStyle: {
                                    textAlign: "center",
                                },
                                field: "lastLoginDate",
                                render: (rowData) => <Box textAlign={"center"}>{FormatMomentString(rowData.lastLoginDate.toString(), "DD MMM YYYY")}</Box>,
                            },
                            {
                                title: "Certificate issued?",
                                field: "certificateSent",
                                render: (rowData) => {
                                    return (
                                        <>
                                            {rowData.certificateSent && (
                                                <>
                                                    <span>Yes </span>
                                                    <span>({FormatMomentString(rowData.lastLoginDate.toString(), "DD MMM YYYY")})</span>
                                                    <span>
                                                        <BlueButton onClick={() => viewModel.downloadCert(rowData.id)} startIcon={<GetAppIcon />}>
                                                            Download
                                                        </BlueButton>
                                                    </span>
                                                </>
                                            )}
                                            {!rowData.certificateSent && <span>No</span>}
                                        </>
                                    );
                                },
                            },
                            {
                                title: "",
                                field: "approved",
                                render: (rowData) => (
                                    <AutoGrid desktopColumns={"1fr 1fr 1fr"}>
                                        <Box minWidth={"170px"} display={"flex"} flexDirection={"rows"} justifyContent={"center"} alignItems={"center"}>
                                            <img alt={"Plane icon"} src={PlaneSVG} />
                                            <BlueButton onClick={() => viewModel.sendForgotPassword(rowData)}>Send forgot password</BlueButton>
                                        </Box>
                                        {/*<Box minWidth={"100px"} display={"flex"} flexDirection={"rows"} justifyContent={"center"} alignItems={"center"}>
                                            <CreateIcon style={{ color: "#337AB7" }} />
                                            <BlueButton onClick={() => viewModel.approveUser(rowData)}>Edit user</BlueButton>
                                        </Box>*/}
                                        <Box minWidth={"110px"} display={"flex"} flexDirection={"rows"} justifyContent={"center"} alignItems={"center"}>
                                            <img alt={"Delete icon"} src={DeleteSVG} />
                                            <RedButton onClick={() => viewModel.deleteUser(rowData)}>Delete user</RedButton>
                                        </Box>
                                    </AutoGrid>
                                ),
                            },
                        ]}
                        isLoading={viewModel.IsLoading}
                        //data={[...viewModel.pendingUsers, viewModel.pendingUsers]}
                        data={viewModel.approvedUsers.filter((a) => a.approved && !a.deleted)}
                    />
                </Box>
            </Box>
        ));
    };

    const approvedDetail = () => {
        return useObserver(() => (
            <Box>
                <Button onClick={() => setValue(1)}>Back</Button>
                <RegisterPage id={StoresInstance.domain.UserDetailId} />
            </Box>
        ));
    };

    return useObserver(() => (
        <>
            {value !== 2 && (
                <AppBar position={"static"}>
                    <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange} aria-label="disabled tabs example">
                        <Tab label="Pending" />
                        <Tab label="Approved" />
                    </Tabs>
                </AppBar>
            )}
            {value == 0 && pendingBox()}
            {value == 1 && approvedBox()}
            {value == 2 && approvedDetail()}
        </>
    ));
};
