/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from "react";
import { StoresInstance } from "Custom/Stores";
import { Centered } from "../../Core/Components";
import { Box, Button, Typography } from "@material-ui/core";
import { StaticBGImg } from "../../Core/Components/Styles";
import regImage from "../Content/regImage.svg";
import { getHistory } from "../../Core/Utils/Utils";

export const ThankYouForRegistering: React.FC = () => {
    useEffect(() => {
        if (StoresInstance.domain.AccountStore.IsLoggedIn && StoresInstance.domain.AccountStore.Approved) {
            getHistory().push("/");
        }
    }, []);

    return (
        <>
            <StaticBGImg alt={""} src={regImage} />

            <Box display={"flex"} justifyItems={"center"} flexDirection={"column"} marginLeft={"15%"} marginRight={"15%"}>
                {StoresInstance.domain.AccountStore.IsLoggedIn ? (
                    <Typography variant={"h2"}>You havent been approved yet</Typography>
                ) : (
                    <Typography variant={"h2"}>Thank you for registering</Typography>
                )}
                <Box paddingTop={"20px"} paddingBottom={"50px"}>
                    <Typography variant={"h5"}>Your registration is being processed, we will get back to you within 24 hours to confirm your registration.</Typography>
                </Box>
                <Button color={"primary"} style={{ width: "300px" }} variant={"contained"} onClick={() => getHistory().push("/")}>
                    Return to the home page
                </Button>
            </Box>
        </>
    );
};
