import React, { useState, useEffect } from "react";
import { withStyles, createStyles, createMuiTheme, Theme, Grid, CircularProgress, Typography } from "@material-ui/core";
import { useTimeout } from "Core/Utils";

interface Props {
    delayBeforeShow?: number;
    loadingText?: string;
}

export const Loader = (props: Props) => {
    const [show, setShow] = useState(false);
    const { start, clear } = useTimeout(() => {
        setShow(true);
    }, props.delayBeforeShow);

    useEffect(() => {
        start();
        return () => {
            clear();
        };
    }, []);

    if (show) {
        return (
            <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: "90vh" }}>
                <Grid item>
                    <CircularProgress />
                </Grid>
                <Grid item>
                    <Typography>{props.loadingText}</Typography>
                </Grid>
            </Grid>
        );
    } else return <></>;
};

Loader.defaultProps = {
    delayBeforeShow: 0,
    loadingText: "Loading...",
};
