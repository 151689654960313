import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Fade, Modal, Typography } from "@material-ui/core";
import styled from "styled-components";
import { EditableInput } from "../../Core/Components/EditableInput";
import { AutoGrid } from "Core/Components/AutoGrid";
import { CSSGrid, TypographyX } from "Core/Components/Styles";
import { StaticBGImg } from "Core/Components/Styles";
import bgImage from "../Content/bgImageCert.svg";
import regImage from "Custom/Content/regImage.svg";
import { useObserver } from "mobx-react-lite";
import { ResponsesModel } from "../Models/ResponsesModel";
import { ResponsesViewModel } from "../ViewModels/ResponsesViewModel";
import { StoresInstance } from "../Stores";
import { Prompt } from "react-router";

import { useBeforeunload } from "react-beforeunload";
import { KeyValuePair } from "../../Core/Models/KeyValuePair";

export const useScroll = () => {
    const elRef = useRef(undefined);
    const executeScroll = () => {
        if (elRef && elRef.current !== null) {
            (elRef.current as any).scrollIntoView();
        }
    };

    return [executeScroll, elRef];
};

export const CertificatePage: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new ResponsesViewModel());
    const [executeScroll1, section1Ref] = useScroll();
    const [executeScroll2, section2Ref] = useScroll();
    const [executeScroll3, section3Ref] = useScroll();
    const [promptOnLeave, setPromptOnLeave] = useState(true);

    const doSubmitSection1 = async (e: any) => {
        if (viewModel.isModelValid()) {
            StoresInstance.coreStore.SendEvent({
                eventCategory: "button",
                eventAction: "click",
                eventLabel: "BeforeActivity",
            });
            viewModel.setPageIndex(1);
            viewModel.setShowSection1(true);
            setTimeout(() => {
                executeScroll1;
            }, 1000);
        }
    };

    const doSubmitSection2 = async (e: any) => {
        if (viewModel.isModelValid()) {
            StoresInstance.coreStore.SendEvent({
                eventCategory: "button",
                eventAction: "click",
                eventLabel: "DuringActivity",
            });
            viewModel.setPageIndex(2);
            viewModel.setShowSection2(true);
            setTimeout(() => {
                executeScroll2;
            }, 1000);
        }
    };

    const doSubmitSection3 = async (e: any) => {
        if (viewModel.isModelValid()) {
            StoresInstance.coreStore.SendEvent({
                eventCategory: "button",
                eventAction: "click",
                eventLabel: "GenerateCertificate",
            });
            setPromptOnLeave(false);
            viewModel.setPageIndex(3);
            viewModel.setShowSection3(true);
            viewModel.sendCompletionEmail();
            setTimeout(() => {
                executeScroll3;
            }, 1000);
        }
    };

    const preSection = () => {
        return (
            <Box marginLeft="15%" marginRight="15%">
                <TypographyX variant="h6" fontWeight={700} paddingTop="15px" paddingBottom={"15px"}>
                    Please tick appropriate boxes:
                </TypographyX>
                <AutoGrid desktopColumns="4fr 1fr" rowGap="38px">
                    <Box>
                        <Typography>Would you like to be regularly updated with information on products and services from the Sharman’s Gutter Refurbishment Programme?</Typography>
                    </Box>
                    <Box>
                        <EditableInput<ResponsesModel>
                            type="radio"
                            viewModel={viewModel}
                            fieldName="updatedWithProducts"
                            selectItems={
                                [
                                    { value: "1", key: "Yes" },
                                    { value: "0", key: "No" },
                                ] as KeyValuePair[]
                            }
                        />
                    </Box>
                    <Box>
                        <Typography>Do you regularly undertake dilapidation surveys or get involved with refurbishments on commercial/industrial properties?</Typography>
                    </Box>
                    <Box>
                        <EditableInput<ResponsesModel>
                            type="radio"
                            viewModel={viewModel}
                            fieldName="undertakeDilapidation"
                            selectItems={
                                [
                                    { value: "1", key: "Yes" },
                                    { value: "0", key: "No" },
                                ] as KeyValuePair[]
                            }
                        />
                    </Box>
                    <Box>
                        <Typography>Have you previously encountered the problem of failing gutters?</Typography>
                    </Box>
                    <Box>
                        <EditableInput<ResponsesModel>
                            type="radio"
                            viewModel={viewModel}
                            fieldName="failingGutters"
                            selectItems={
                                [
                                    { value: "1", key: "Yes" },
                                    { value: "0", key: "No" },
                                ] as KeyValuePair[]
                            }
                        />
                    </Box>
                    <Box>
                        <Typography>Are there any projects that you are working on where our systems could be used?</Typography>
                    </Box>
                    <Box>
                        <EditableInput<ResponsesModel>
                            type="radio"
                            viewModel={viewModel}
                            fieldName="systemUseful"
                            selectItems={
                                [
                                    { value: "1", key: "Yes" },
                                    { value: "0", key: "No" },
                                ] as KeyValuePair[]
                            }
                        />
                    </Box>
                    <Box>
                        <Typography>Would you be interested in allowing us to conduct a condition report on your next project industrial roof project?</Typography>
                    </Box>
                    <Box>
                        <EditableInput<ResponsesModel>
                            type="radio"
                            viewModel={viewModel}
                            fieldName="allowConditionReport"
                            selectItems={
                                [
                                    { value: "1", key: "Yes" },
                                    { value: "0", key: "No" },
                                ] as KeyValuePair[]
                            }
                        />
                    </Box>
                </AutoGrid>
                <TypographyX paddingBottom="5px" paddingTop="25px">
                    Do you read any of the following professional publications?
                </TypographyX>
                <AutoGrid desktopColumns="1fr 1fr 1fr 1fr" tabletColumns="2fr 2fr">
                    <EditableInput<ResponsesModel> viewModel={viewModel} label="Building Surveying Journal" fieldName="buildingSurveyingJournal" type="checkbox" />
                    <EditableInput<ResponsesModel> viewModel={viewModel} label="Architects Data File" fieldName="architectsDataFile" type="checkbox" />
                    <EditableInput<ResponsesModel> viewModel={viewModel} label="RIBA Journal Magazine" fieldName="riba" type="checkbox" />
                    <EditableInput<ResponsesModel> viewModel={viewModel} label="Roofing, Cladding and Insulation" fieldName="roofing" type="checkbox" />
                </AutoGrid>
                <AutoGrid desktopColumns="2fr 1fr 1fr 1fr" tabletColumns="2fr 2fr">
                    <EditableInput<ResponsesModel> shrink={true} viewModel={viewModel} label="Enter any additional publications" fieldName="publications" type="text" />
                </AutoGrid>
                <div className="break" />
            </Box>
        );
    };

    const getSection0 = () => {
        return (
            <>
                <Box marginLeft="15%" marginRight="15%">
                    <AutoGrid desktopColumns="1fr" tabletColumns="1fr" rowGap="35px">
                        <TypographyX fontSize="12px">
                            This activity equates to 1 hour of CPD. This certificate should be kept within your CPD record. Now you have completed this CPD activity, please reflect
                            and complete the following questions. These will help you reflect on how your learning can be applied to your role, and how to record it for formal CPD
                            purposes in terms of learning outcomes:
                        </TypographyX>
                        <TypographyX variant="h6" fontWeight={800} className="redText">
                            Why gutters fail and the options to repair, replace and refurbish: A specifier’s guide.
                        </TypographyX>
                        <TypographyX variant="h6" fontWeight={700}>
                            Before the activity:
                        </TypographyX>
                    </AutoGrid>
                    <TypographyX fontWeight={700}>What was the key reason for you choosing to undertake this activity?</TypographyX>
                    <EditableInput<ResponsesModel>
                        editMode={!viewModel.showSection1}
                        type={"text"}
                        multiline={true}
                        maxLength={375}
                        rows={5}
                        shrink={true}
                        viewModel={viewModel}
                        label="Your response"
                        fieldName={"keyReasonForChoosingActivity"}
                    />
                    {!viewModel.showSection1 && (
                        <Box textAlign="center" paddingTop="20px">
                            <Button data-cy="Continue-button" onClick={doSubmitSection1} fullWidth={false} variant="contained" color="primary" className="vidButton" size="large">
                                Continue to video
                            </Button>
                        </Box>
                    )}
                </Box>
            </>
        );
    };

    const getSection1 = () => {
        return (
            <>
                <Fade ref={section1Ref} in timeout={1000}>
                    <Box id={"section1"} marginLeft="15%" marginRight="15%">
                        <Box className={"video-container"}>
                            <iframe
                                src="https://www.youtube.com/embed/Zi4d9vrank4"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </Box>
                        <AutoGrid paddingTop="35px">
                            <TypographyX variant="h6" fontWeight={700}>
                                During the activity:
                            </TypographyX>
                            <EditableInput<ResponsesModel>
                                shrink={true}
                                multiline={true}
                                rows={5}
                                maxLength={375}
                                editMode={!viewModel.showSection2}
                                viewModel={viewModel}
                                label="Were there any key takeaways or ideas for practice you thought of?"
                                fieldName={"keyTakeawaysOrIdeasForPractice"}
                                type={"text"}
                            />
                            <EditableInput<ResponsesModel>
                                shrink={true}
                                multiline={true}
                                rows={5}
                                maxLength={375}
                                editMode={!viewModel.showSection2}
                                viewModel={viewModel}
                                label="What was the most relevant part in relation to your role?"
                                fieldName={"mostRelevantPartInRelation"}
                                type={"text"}
                            />
                        </AutoGrid>
                        {!viewModel.showSection2 && (
                            <Box textAlign="center" paddingTop="20px">
                                <Button
                                    data-cy="Continue-button1"
                                    onClick={doSubmitSection2}
                                    fullWidth={false}
                                    variant="contained"
                                    color="primary"
                                    className="vidButton"
                                    size="large"
                                >
                                    I have watched the video
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Fade>
                );
            </>
        );
    };

    const getSection2 = () => {
        return (
            <Fade ref={section2Ref} in timeout={1000}>
                <Box marginLeft="15%" marginRight="15%">
                    <AutoGrid paddingTop="35px">
                        <TypographyX variant="h6" fontWeight={700}>
                            After the activity:
                        </TypographyX>

                        <EditableInput<ResponsesModel>
                            shrink={true}
                            multiline={true}
                            rows={5}
                            maxLength={375}
                            viewModel={viewModel}
                            editMode={!viewModel.showSection3}
                            label="How could you build on your learning?"
                            fieldName={"howCouldYouBuildOnYourLearning"}
                            type={"text"}
                        />
                        <EditableInput<ResponsesModel>
                            shrink={true}
                            multiline={true}
                            rows={5}
                            maxLength={375}
                            viewModel={viewModel}
                            editMode={!viewModel.showSection3}
                            label="What could you share with your colleagues?"
                            fieldName={"whatCouldYouShare"}
                            type={"text"}
                        />
                        <EditableInput<ResponsesModel>
                            shrink={true}
                            multiline={true}
                            rows={5}
                            maxLength={375}
                            viewModel={viewModel}
                            editMode={!viewModel.showSection3}
                            label="Significant learning points to transfer to a CPD record if required:"
                            fieldName={"significantLearningPoints"}
                            type={"text"}
                        />
                    </AutoGrid>
                    {!viewModel.showSection3 && (
                        <Box textAlign="center" paddingTop="20px">
                            <Button data-cy="Continue-button2" onClick={doSubmitSection3} fullWidth={false} variant="contained" color="primary" className="vidButton" size="large">
                                Generate certificate
                            </Button>
                        </Box>
                    )}
                </Box>
            </Fade>
        );
    };

    const getSection3 = () => {
        return (
            <Fade ref={section3Ref} in timeout={1000}>
                <Box textAlign="center" paddingTop="20px">
                    <Button
                        data-cy="Continue-button3"
                        onClick={() => viewModel.downloadCert()}
                        fullWidth={false}
                        variant="contained"
                        color="primary"
                        className="vidButton"
                        size="large"
                    >
                        {viewModel.IsLoading ? <span>Please Wait...</span> : <span>Download Certificate</span>}
                    </Button>
                </Box>
            </Fade>
        );
    };

    /*    useEffect(() => {
        window.addEventListener("beforeunload", confirmExit);
        return () => {
            window.removeEventListener("beforeunload", confirmExit);
        };
    }, []);

    function confirmExit(e: any) {
        if (promptOnLeave) {
            debugger;
            e.preventDefault();
            e.returnValue = "Are you sure you want to leave";
        }
    }*/
    //useBeforeunload((e: any) => return);
    useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        window.addEventListener("unload", handleEndConcert);

        return () => {
            window.removeEventListener("beforeunload", alertUser);
            window.removeEventListener("unload", handleEndConcert);
            handleEndConcert();
        };
    }, []);
    const alertUser = (e: any) => {
        if (promptOnLeave) {
            e.preventDefault();
            e.returnValue = "";
        }
    };
    const handleEndConcert = async () => {};

    return useObserver(() => (
        <>
            {/*<Prompt when={false} message={"Dont leave"} />*/}
            <Box marginLeft="25%" marginRight="15%">
                <StaticBGImg alt={""} src={regImage} />
                <AutoGrid desktopColumns="1fr" tabletColumns="1fr">
                    <Box>
                        <img src={bgImage} alt="" className="mainLogo1" />
                    </Box>
                </AutoGrid>
            </Box>
            {preSection()}
            {getSection0()}
            {viewModel.showSection1 && getSection1()}
            {viewModel.showSection2 && getSection2()}
            {viewModel.showSection3 && getSection3()}
            {/* <Modal
              title={"modal"}
              visible={modalVisible}
              onCancel={() => this.closeModal(() => {})}
              onOk={this.handleConfirmNavigationClick}
            />*/}
        </>
    ));
};
