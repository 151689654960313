import * as Mui from "@material-ui/core";

import { React, StoresInstance, styled, useEffect, useObserver, useRouter, useState } from "../../../Core/Base";

import { EditableInput } from "../../../Core/Components/EditableInput";
import { Role } from "../../Models";
import RoleViewModel from "../../ViewModels/RoleViewModel";
import { CSSGrid } from "../../../Core/Components/Styles";

const domainStores = StoresInstance.domain;
export const Container = styled(Mui.Box)``;
export const DetailContainer = styled(Mui.Box)`
    position: relative;
`;

export const RoleDetails: React.FC = () => {
    const [roleViewModel] = useState(() => new RoleViewModel());
    const { history, match } = useRouter();
    const [newRole, setNewRole] = useState(false);

    let { id } = match.params as any;

    React.useEffect(() => {
        if (id === "0") {
            setNewRole(true);
        }
    }, []);

    React.useEffect(() => {
        if (history.location.pathname === "/roleslist/role/0") {
            setNewRole(true);
        }
    }, [history.location.pathname]);

    return useObserver(() => (
        <Container>
            <Mui.Box marginTop="45px" marginBottom="10px" display="flex">
                <Mui.Box flexGrow={1}>
                    <Mui.Typography variant="h1" color="textPrimary">
                        Role
                    </Mui.Typography>
                </Mui.Box>
            </Mui.Box>
            <hr />
            <DetailPanel newRole={newRole} id={id} roleViewModel={roleViewModel} />
        </Container>
    ));
};

interface DetailPanelProps {
    newRole: boolean;
    id: string;
    roleViewModel: RoleViewModel;
}
export const DetailPanel: React.FC<DetailPanelProps> = (props) => {
    const { roleViewModel } = props;
    let role: Role = new Role("");

    //const [editable, seteditable] = useState(false);
    const [loader, setLoader] = useState(false);
    const { history } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        if (props.id !== "0") {
            let currentRole = roleViewModel.getRole(props.id);
            if (currentRole) {
                roleViewModel.setRole(currentRole, false);
            } else {
                roleViewModel.loadRolesAsync().then(() => {
                    let currentRole = roleViewModel.getRole(props.id);
                    if (currentRole) {
                        roleViewModel.setRole(currentRole, false);
                    }
                });
            }
        } else {
            roleViewModel.setRole(role, true);
        }
    }, []);

    const doSubmit = (e: any) => {
        e.preventDefault();
        if (!roleViewModel.isModelValid()) {
            setErrorMessage("Problem");
        } else {
            setErrorMessage("");
            setLoader(true);
            roleViewModel.postRoleDetailAsync().then(() => {
                setLoader(false);
                domainStores.ShowSnackBar("Role saved successfully");
                history.push("/admin");
            });
        }
    };

    return useObserver(() => (
        <form onSubmit={doSubmit} id="detailsPanelForm">
            <DetailContainer>
                {errorMessage !== "" && (
                    <Mui.Typography variant="caption" style={{ color: "red" }}>
                        {errorMessage}
                    </Mui.Typography>
                )}
                <Mui.Box marginTop="-70px" display="flex" textAlign="right">
                    <Mui.Box flexGrow={1}>
                        <Mui.Box display="flex">
                            <Mui.Box justifyContent="flex-end" flexGrow={1} paddingRight="20px">
                                <Mui.Button id="save" variant="contained" color="primary" type="submit" disabled={loader}>
                                    {!loader ? "Save" : "Saving"}
                                </Mui.Button>
                                <span style={{ paddingRight: "20px" }} />
                                <Mui.Button
                                    id="cancel"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        history.push("/admin");
                                    }}
                                >
                                    Cancel
                                </Mui.Button>
                            </Mui.Box>
                        </Mui.Box>
                    </Mui.Box>
                </Mui.Box>

                <CSSGrid columns={"400px"}>
                    <EditableInput viewModel={roleViewModel} label="Name" fieldName="name" />
                </CSSGrid>
            </DetailContainer>
        </form>
    ));
};
