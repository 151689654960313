export const Client = {
    Root: "/",
};

export const Server = {
    Api: {
        Account: {
            Login: "./api/account/login",
            Logout: "./api/account/logout",
            ResetPassword: {
                Reset: "./api/account/resetpassword",
                VerifyToken: "./api/account/resetpasswordtoken",
            },
            SetPassword: "./api/account/setpassword",
            ForgotPassword: "./api/account/forgotpassword",
        },
        Admin: {
            ResetFailedLoginAttemptsCount: "./api/user/ResetLoginAttempts",
        },
        LiteFormEmail: {
            PostLiteFormAsync: "/api/LiteForm/PostLiteFormAsync",
        },
    },
};

export const Admin = {
    Root: "/admin",
};
