import React from "react";
import { RoleListContainer } from "./RoleListContainer";

export const RolesList: React.FC = () => {
    return (
        <>
            <div>
                <RoleListContainer />
            </div>
        </>
    );
};
