import { Avatar, Box, Button, Fade, Link, Paper, Theme, Typography, Grid } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Stores, StoresContext, StoresInstance } from "Custom/Stores";

import { EditableCopyViewModel } from "../ViewModels/EditableCopyViewModel";
import { EditableInput } from "Core/Components/EditableInput";
import { AutoGrid } from "Core/Components/AutoGrid";
import { LoginModel } from "../Models";
import { LoginViewModel } from "../ViewModels/LoginViewModel";
import { Redirect } from "react-router";
import { getHistory, nameofFactory } from "Core/Utils/Utils";
import { useRouter } from "Core/Utils";
import Image34 from "Custom/Content/Image 34.jpg";
import { BoxMiddle, Cell, StaticBGImg, TypographyX } from "Core/Components/Styles";
import mainLogo from "../Content/mainLogoImage.svg";
import mainLogoAdmin from "../Content/mainLogoImageAdmin.svg";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components/macro";

//let loginViewModel = new LoginViewModel();

const domainStores = StoresInstance.domain;
const nameof = nameofFactory<LoginModel>();

const CommonButton = styled(Button)`
    color: white;
    font-size: 18px;
    width: 282px;
`;

const LoginButton = styled(CommonButton)`
    color: red;
    font-size: 18px;
    background-color: white;
    width: 100%;
`;

const RegButton = styled(CommonButton)`
    color: white;
    background-color: red;
    width: 100%;
    &:hover {
        background-color: lightgray;
    }
`;

interface Props {
    admin: boolean;
}

export const LoginPage: React.FunctionComponent<Props> = ({ admin }: Props) => {
    const [viewModel] = useState(() => new LoginViewModel());
    const [viewEditableCopyModel] = useState(() => new EditableCopyViewModel());
    const { history, location, match } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState("");

    if (viewModel.isLoggedIn) {
        return <Redirect to={"/"} />;
    }

    useEffect(() => {}, []);

    const doSubmit = async (e: any) => {
        e.preventDefault();
        if (viewModel.isModelValid()) {
            const apiResult = await viewModel.login();
            if (apiResult !== null) {
                if (apiResult.wasSuccessful) {
                    domainStores.AccountStore.getLoginState(apiResult.payload);
                    const { from } = (location.state as any) || { from: { pathname: "/" } };
                    if (from) {
                        history.push(from.pathname);
                    }
                } else {
                    if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                        setErrorMessage("* " + apiResult.errors[0].message);
                    } else {
                        setErrorMessage("* Unknown error has occurred.");
                    }
                }
            }
        }
    };

    const goToForgotPassword = () => {
        history.push("/forgotpassword");
    };

    return useObserver(() => (
        <Fade in timeout={1000}>
            <>
                <Box position="absolute" bottom="0">
                    <AutoGrid justifyItems="flex-end" desktopColumns="1fr 1fr" tabletColumns="1fr" rowGap="1em" columnGap={0}>
                        <TypographyX customcolor={"white"} fontSize={"44px"} style={{ paddingBottom: "20px", paddingRight: "70px" }}>
                            CPD learning tool
                        </TypographyX>
                    </AutoGrid>
                    <AutoGrid desktopColumns="1fr 1fr" tabletColumns="1fr" rowGap="1em" columnGap={0}>
                        <Box style={{ backgroundColor: "white" }}>
                            <Box padding="20px 50px 60px 75px" marginLeft="36%">
                                <TypographyX variant="h4" color="primary" paddingBottom="25px">
                                    Welcome to our online CPD
                                </TypographyX>
                                <TypographyX paddingBottom="25px" style={{ color: "red" }}>
                                    Why gutters fail and the options to repair, replace and refurbish: A specifier's guide.
                                </TypographyX>
                                <TypographyX paddingBottom="25px" fontSize="12px">
                                    To access this CPD you are required to register your details. Once your registration is completed, your access will be approved by one of our
                                    team. You will receive a confirmation email within 24 hours, which will allow you to login and view the CPD. Please check your junk mail for
                                    confirmation.{" "}
                                </TypographyX>
                                <RegButton data-cy="reg-button" onClick={() => getHistory().push("/register")} color="secondary" className="mainButton">
                                    Register now for access
                                </RegButton>
                            </Box>
                        </Box>
                        <Box style={{ backgroundColor: "#3a3a3c" }}>
                            <Box padding="20px 20px 60px 50px" marginRight="40%">
                                <TypographyX variant="h4" style={{ color: "white" }}>
                                    Already registered ?
                                </TypographyX>
                                <StaticBGImg alt={""} src={Image34} />
                                <form onSubmit={doSubmit}>
                                    {errorMessage !== "" && (
                                        <Typography variant="caption" style={{ color: "red" }}>
                                            {errorMessage}
                                        </Typography>
                                    )}
                                    <AutoGrid desktopColumns="1fr" rowGap={"1em"} columnGap={"1em"} paddingTop="45px">
                                        <EditableInput
                                            color={"white"}
                                            shrink={true}
                                            paddingTop={"-10px"}
                                            type="email"
                                            label="Email Address:"
                                            validateOnBlur={true}
                                            viewModel={viewModel}
                                            fieldName="email"
                                        />
                                        <EditableInput
                                            color={"white"}
                                            shrink={true}
                                            style={{ backgroundColor: "white!important" }}
                                            type="password"
                                            label="Password:"
                                            viewModel={viewModel}
                                            fieldName="password"
                                        />
                                        <Link data-cy="forgot-password" style={{ color: "white" }} onClick={() => goToForgotPassword()}>
                                            Forgot Password?
                                        </Link>
                                        <Box paddingTop="25px">
                                            <LoginButton data-cy="login-button" type="submit" fullWidth variant="contained" color="secondary">
                                                {isLoading ? "Please Wait..." : "Login"}
                                            </LoginButton>
                                        </Box>
                                    </AutoGrid>
                                </form>
                            </Box>
                        </Box>
                    </AutoGrid>
                </Box>
            </>
        </Fade>
    ));
};
