import React from "react";

let defaultPrefix = "id";
let lastId = 0;

export interface Props {
    defaultPrefix?: string;
}

export class ResetElementIdGenerator extends React.Component<Props> {
    public constructor(props: Props) {
        super(props);

        lastId = 0;

        if (props.defaultPrefix != null) {
            defaultPrefix = props.defaultPrefix;
        }
    }

    public render() {
        return null;
    }
}

export const getElementId = (prefix?: string) => `${prefix || defaultPrefix}_${++lastId}`;
