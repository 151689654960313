import { ViewModelBase } from "./ViewModelBase";
import { observable, action, computed, runInAction } from "mobx";
import { FieldType } from "../Utils/Utils";

export class LogViewerViewModel extends ViewModelBase<any> {
    @observable public FileList = observable<string>([]);
    @observable public LogLoaded = false;
    @observable public LogContents = {};

    constructor() {
        super({});
    }

    @action
    public FetchLogListAsync = async (): Promise<boolean> => {
        let apiResult = await this.Get<[]>("/api/logs/getlist");
        runInAction(() => {
            this.FileList.clear();

            if (apiResult.wasSuccessful && apiResult.payload.length > 0) {
                apiResult.payload.forEach((item: string, index: number) => {
                    this.FileList.push(item);
                });
            }
        });

        return apiResult.wasSuccessful;
    };

    public FetchLogFileAsync = async (file: string): Promise<boolean> => {
        let apiResult = await this.Get<string>(`/api/logs/getlog?file=${file}`);
        runInAction(() => {
            if (apiResult.wasSuccessful && apiResult.payload.length > 0) {
                this.LogContents = JSON.parse(apiResult.payload);
                this.LogLoaded = true;
            }
        });

        return apiResult.wasSuccessful;
    };

    public DownloadFileAsync = async (file: string): Promise<boolean> => {
        let apiResult = await this.Get<string>(`/api/logs/downloadlog?file=${file}`);
        const url = window.URL.createObjectURL(new Blob([apiResult.payload]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();

        return apiResult.wasSuccessful;
    };

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
