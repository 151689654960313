import * as MobX from "mobx";

import { IsEmail, IsNotEmpty } from "class-validator";

import { ModelBase } from "../../Core/Models/ModelBase";

export class ResetPasswordModel extends ModelBase<ResetPasswordModel, ResetPasswordModelDTO> {
    @MobX.observable
    @IsNotEmpty({ message: "Password is required" })
    public newPassword: string = "";

    @MobX.observable
    public confirmPassword: string = "";

    @MobX.observable
    public token: string = "";

    @MobX.action
    fromDto(model: ResetPasswordModelDTO): void {
        this.newPassword = model.newPassword;
        this.token = model.token;
    }

    toDto(model: ResetPasswordModel): void {}
}

export interface ResetPasswordModelDTO {
    newPassword: string;
    token: string;
}
