import React from "react";
import { Route, Redirect, RouteProps, RouteComponentProps } from "react-router";
import { DefaultLayout } from "Custom/Views/Layouts/DefaultLayout";

interface Props extends RouteProps {
    component: any;
    isAllowed: () => boolean;
    layout?: any;
}

// https://tylermcginnis.com/react-router-protected-routes-authentication/

// const PrivateRoute = (props: Props) => {
export const PrivateRoute: React.FC<Props> = (props) => {
    // EN: Destructure component and RENAME it to Component!
    const { isAllowed, component: Component, layout: NewLayout, ...rest } = props;

    return (
        <Route
            {...rest}
            render={(props) => {
                // return isAllowed() && <Component {...props} />;
                return isAllowed() === true ? (
                    <NewLayout>
                        <Component {...props} />
                    </NewLayout>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                );
            }}
        />
    );
};
PrivateRoute.defaultProps = {
    layout: DefaultLayout,
};
// Usage
{
    /* <DecisionRoute
	path="/signin"
	exact={true}
	trueComponent={redirectStart}
	falseComponent={SignInPage}
	decisionFunc={isAuth}
/>; */
    // const redirectStart = props => <Redirect to="/orders" />
}
interface DProps extends RouteProps {
    trueComponent: any;
    falseComponent: any;
    decisionFunc: any;
}
export const DecisionRoute: React.FC<DProps> = ({ trueComponent, falseComponent, decisionFunc, ...rest }) => {
    return <Route {...rest} render={decisionFunc() ? trueComponent : falseComponent} />;
};
