import { AccountStatus, ApiResult } from "Core/Models";
import { RegisterUserModel } from "Custom/Models/RegisterUserModel";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import validator from "validator";
import { FieldType, getHistory } from "../../Core/Utils/Utils";
import { StoresInstance } from "../Stores";
import { ResponsesModel } from "../Models/ResponsesModel";
import { action, observable, runInAction } from "mobx";
import { ResponsesViewModel } from "./ResponsesViewModel";

//Pass in <any> as the generic type because we are showing the use of nested classes
export class RegisterUserViewModel extends ViewModelBase<RegisterUserModel> {
    @observable public response: ResponsesModel = {} as ResponsesModel;
    @observable public isFreeGift: boolean = false;
    @observable public rewardRegistered: boolean = false;
    @observable public userAlreadyRequestedGift: boolean = false;
    @observable public errorMessage: string = "";
    public responsesViewModel: ResponsesViewModel = new ResponsesViewModel();

    constructor() {
        super(new RegisterUserModel());
        console.log("Register View Model Created");
        this.setDecorators(RegisterUserModel);
    }

    public register = async (): Promise<ApiResult<AccountStatus>> => {
        let retval: any;
        if (!this.isFreeGift) {
            retval = await this.Post<AccountStatus>("/api/account/register", this.getModel);
            if (retval.wasSuccessful) {
                getHistory().push("/thankyou");
            }
        } else {
            retval = await this.Post<AccountStatus>("/api/account/sendgift", this.getModel);
            if (retval.wasSuccessful) {
                runInAction(() => {
                    this.rewardRegistered = true;
                });
            }
        }

        return retval;
    };
    public getUserDetail = async (id: string): Promise<ApiResult<RegisterUserModel>> => {
        let retval = await this.Get<RegisterUserModel>("/api/user/getuserDetail/" + id);
        if (retval.wasSuccessful) {
            runInAction(() => {
                this.userAlreadyRequestedGift = retval.payload.sendGift;
                this.setModel(retval.payload);
                this.responsesViewModel.setModel(retval.payload as any);
            });
        }
        return retval;
    };
    public checkIfUserHasRequestedGift = async () => {
        let retval = await this.getUserDetail(StoresInstance.domain.AccountStore.UserName);
        //let retval = await this.Get<any>("/api/account/HasUserRequestedGift/");
        if (retval.wasSuccessful) {
            runInAction(() => {
                this.userAlreadyRequestedGift = retval.payload.sendGift;
                this.setIsFreeGift(true);
            });
        }
        return retval.wasSuccessful;
    };
    public saveChanges = async () => {
        let retval = await this.Post<AccountStatus>("/api/account/update", this.getModel);
    };
    @action
    public setErrorMessage = (message: string) => {
        this.errorMessage = message;
    };
    public downloadCert = async () => {
        //this.model.setValue("userId", StoresInstance.domain.AccountStore.UserName);
        let response = (await this.Get("/api/account/downloadcertificatebyuserid/" + StoresInstance.domain.UserDetailId, { responseType: "blob" })) as any;
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CPD_Certificate_Plygene.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
    };

    @action
    public setIsFreeGift = (state: boolean) => {
        this.isFreeGift = state;
    };
    // @ts-ignore
    public async isFieldValid(fieldName: keyof FieldType<RegisterUserModel>, value: any): any {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (StoresInstance.domain.UserDetailId.length > 0) return true;

        if (fieldName === "password" && this.isFreeGift) {
            errorMessage = "";
            isValid = errorMessage === "";
        }

        if (fieldName === "confirmPassword" && !this.isFreeGift) {
            errorMessage = this.isConfirmPasswordValid;
            isValid = errorMessage === "";
        }

        if (fieldName === "sendGift" && this.isFreeGift) {
            if (!value) {
                errorMessage = "Please tick the check box";
                isValid = false;
            }
        }

        if (fieldName === this.getContext().address1) {
            if (validator.isEmpty(value)) {
                errorMessage = "Address 1 is required";
                isValid = false;
            }
        }

        if (fieldName === this.getContext().postCode) {
            if (validator.isEmpty(value)) {
                errorMessage = "Postcode is required";
                isValid = false;
            } else if (!validator.isPostalCode(value, "GB")) {
                errorMessage = "Postcode is not valid";
                isValid = false;
            }
        }

        if (isValid && fieldName === "email" && !this.isFreeGift) {
            let apiResult = await this.Post<AccountStatus>("/api/account/doesuserexist", this.model);
            if (!apiResult.wasSuccessful || apiResult.payload.exists) {
                errorMessage = "This email address is already registrered";
                isValid = false;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);
        if (!isValid) {
            console.log(fieldName + " is not valid");
        }
        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public get isConfirmPasswordValid(): string {
        if (this.getValue("password") !== this.getValue("confirmPassword") && this.getValue("password") !== "") return "Passwords must match";
        return "";
    }
}
