import * as Mui from "@material-ui/core";

import { React, StoresInstance, styled, useEffect, useObserver, useRouter, useState } from "../../../Core/Base";

import { EditableInput } from "../../../Core/Components/EditableInput";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { Role } from "Custom/Models";
import RoleViewModel from "../../ViewModels/RoleViewModel";
import { User } from "../../Models";
import UserViewModel from "../../../Custom/ViewModels/UserViewModel";
import { CSSGrid } from "../../../Core/Components/Styles";

const domainStores = StoresInstance.domain;
export const Container = styled(Mui.Box)``;
export const DetailContainer = styled(Mui.Box)`
    position: relative;
`;

export const UserDetails: React.FC = () => {
    const [userViewModel] = useState(() => new UserViewModel());
    const { history, match } = useRouter();
    const [newUser, setNewUser] = useState(false);

    let { id } = match.params as any;

    React.useEffect(() => {
        if (id === "0") {
            setNewUser(true);
        }
    }, []);

    React.useEffect(() => {
        if (history.location.pathname === "/userlist/user/0") {
            setNewUser(true);
        }
    }, [history.location.pathname]);

    return useObserver(() => (
        <Container>
            <Mui.Box marginTop="45px" marginBottom="10px" display="flex">
                <Mui.Box flexGrow={1}>
                    <Mui.Typography variant="h1" color="textPrimary">
                        User
                    </Mui.Typography>
                </Mui.Box>
            </Mui.Box>
            <hr />
            <DetailPanel newUser={newUser} id={id} userViewModel={userViewModel} />
            <Mui.Button variant="contained" color="primary" onClick={() => userViewModel.resetFailedLoginAttempts()}>
                Reset Failed Login Attempts Count
            </Mui.Button>
            <div>{userViewModel.IsLoading ? "Resetting..." : ""}</div>
            <div className="error">{userViewModel.resetLoginAttemptsError}</div>
        </Container>
    ));
};

interface DetailPanelProps {
    newUser: boolean;
    id: string;
    userViewModel: UserViewModel;
}
export const DetailPanel: React.FC<DetailPanelProps> = (props) => {
    const { userViewModel } = props;
    const [roleViewModel] = useState(() => new RoleViewModel());
    let user: User = new User("");

    //const [editable, seteditable] = useState(false);
    const [loader, setLoader] = useState(false);
    const { history } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");

    const getUserRoles = async () => {
        const rolesResponse = await roleViewModel.loadRolesAsync();

        if (rolesResponse.wasSuccessful) {
            const response = await userViewModel.getUserRoles();

            if (!response.wasSuccessful) {
                if (response.errors) {
                    setErrorMessage(response.errors[0].message);
                } else {
                    setErrorMessage("There was a problem getting this user's roles.");
                }
            }
        } else {
            if (rolesResponse.errors) {
                setErrorMessage(rolesResponse.errors[0].message);
            } else {
                setErrorMessage("There was a problem getting the roles.");
            }
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (props.id !== "0") {
            let currentUser = userViewModel.getUser(props.id);
            if (currentUser) {
                userViewModel.setUser(currentUser, false);
                getUserRoles();
            } else {
                userViewModel.loadUsersAsync().then(() => {
                    let currentUser = userViewModel.getUser(props.id);
                    if (currentUser) {
                        userViewModel.setUser(currentUser, false);
                        getUserRoles();
                    }
                });
            }
        } else {
            userViewModel.setUser(user, true);
        }
    }, []);

    const doSubmit = (e: any) => {
        e.preventDefault();
        if (!userViewModel.isModelValid()) {
            setErrorMessage("Problem");
        } else {
            setErrorMessage("");
            setLoader(true);
            userViewModel.postUserDetailAsync().then(() => {
                setLoader(false);
                domainStores.ShowSnackBar("User saved successfully");
                history.push("/admin");
            });
        }
    };

    return useObserver(() => (
        <form onSubmit={doSubmit} id="detailsPanelForm">
            <DetailContainer>
                {errorMessage !== "" && (
                    <Mui.Typography variant="caption" style={{ color: "red" }}>
                        {errorMessage}
                    </Mui.Typography>
                )}
                <Mui.Box marginTop="-70px" display="flex" textAlign="right">
                    <Mui.Box flexGrow={1}>
                        <Mui.Box display="flex">
                            <Mui.Box justifyContent="flex-end" flexGrow={1} paddingRight="20px">
                                <Mui.Button id="save" variant="contained" color="primary" type="submit" disabled={loader}>
                                    {!loader ? "Save" : "Saving"}
                                </Mui.Button>
                                <span style={{ paddingRight: "20px" }} />
                                <Mui.Button
                                    id="cancel"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        history.push("/admin");
                                    }}
                                >
                                    Cancel
                                </Mui.Button>
                            </Mui.Box>
                        </Mui.Box>
                    </Mui.Box>
                </Mui.Box>

                <CSSGrid columns={"400px"}>
                    <EditableInput viewModel={userViewModel} label="Username" fieldName="email" />
                    <EditableInput viewModel={userViewModel} label="First name" fieldName="firstName" />
                    <EditableInput viewModel={userViewModel} label="Last name" fieldName="lastName" />
                    <EditableInput viewModel={userViewModel} label="Password" fieldName="password" />
                    <EditableInput
                        viewModel={userViewModel}
                        type="multiselect"
                        label="Roles"
                        fieldName="userRoles"
                        selectItems={domainStores.RoleStore.getRoles.map((role) => ({ value: role, key: role.name })) as KeyValuePair[]}
                        inputProps={{
                            renderValue: (selected: Role[]) => (
                                <div>
                                    {selected.map((value) => (
                                        <Mui.Chip key={value.id} label={value.name} />
                                    ))}
                                </div>
                            ),
                        }}
                    />
                </CSSGrid>
            </DetailContainer>
        </form>
    ));
};
