import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsEmail, IsNotEmpty } from "class-validator";
import { IObservable } from "mobx";

export class ResponsesModel extends ModelBase<ResponsesModel, ResponsesModel> {
    public userId: string = "";
    @MobX.observable
    public keyReasonForChoosingActivity: string = "";

    @MobX.observable
    public keyTakeawaysOrIdeasForPractice: string = "";

    @MobX.observable
    public mostRelevantPartInRelation: string = "";

    @MobX.observable
    public howCouldYouBuildOnYourLearning: string = "";

    @MobX.observable
    public whatCouldYouShare: string = "";

    @MobX.observable
    public significantLearningPoints: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Please select an option" })
    public updatedWithProducts: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Please select an option" })
    public undertakeDilapidation: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Please select an option" })
    public failingGutters: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Please select an option" })
    public systemUseful: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Please select an option" })
    public allowConditionReport: string = "";

    @MobX.observable
    public buildingSurveyingJournal: boolean = false;

    @MobX.observable
    public architectsDataFile: boolean = false;

    @MobX.observable
    public riba: boolean = false;

    @MobX.observable
    public roofing: boolean = false;

    @MobX.observable
    public publications: number | string = "";

    fromDto(model: ResponsesModel): void {}

    toDto(model: ResponsesModel): void {}
}
