import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { BlankModel } from "../Models/BlankModel";
import { FieldType } from "../Utils/Utils";
import { RegisterUserModel } from "../../Custom/Models/RegisterUserModel";

//extend viewmodel base and passing your model as the generic type
export class AutoGridViewModel extends ViewModelBase {
    //Singleton instance of class
    private static _instance: AutoGridViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    constructor() {
        super(null, false);
    }

    public FormatAreas = (areas: string[]) => {
        if (areas && areas.length > 0) {
            return areas.map((area) => `"${area}"`).join(" ");
        }
        return "";
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
