import { action, computed, observable } from "mobx";
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { LoginModel } from "../Models";
import { AccountStatus, ApiResult } from "Core/Models";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { TestModel } from "../../Core/Models/TestModel";
import { ResponsesModel } from "../Models/ResponsesModel";
import { StoresInstance } from "../Stores";

export class ResponsesViewModel extends ViewModelBase<ResponsesModel> {
    @observable public pageIndex: number = 0;
    @observable public showSection1: boolean = false;
    @observable public showSection2: boolean = false;
    @observable public showSection3: boolean = false;

    constructor() {
        super(new ResponsesModel());
        console.log("Responses View Model Created");
        //EN - Havent figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(ResponsesModel);
        //(window as any).model = this.model;
        //(window as any).proxy = proxy;
    }

    @action
    public setShowSection1 = (state: boolean) => {
        this.showSection1 = state;
    };

    @action
    public setShowSection2 = (state: boolean) => {
        this.showSection2 = state;
    };

    @action
    public setShowSection3 = (state: boolean) => {
        this.showSection3 = state;
    };

    @action
    public setPageIndex = (state: number) => {
        this.pageIndex = state;
    };

    public downloadCert = async () => {
        StoresInstance.coreStore.SendEvent({
            eventCategory: "button",
            eventAction: "click",
            eventLabel: "DownloadCertificate",
        });
        this.model.setValue("userId", StoresInstance.domain.AccountStore.UserName);
        let response = (await this.Post("/api/account/downloadcertificate", this.getModel, { responseType: "blob" })) as any;
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CPD_Certificate_Plygene.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
    };
    public sendCompletionEmail = async () => {
        //this.model.setValue("userId", StoresInstance.domain.AccountStore.UserName);
        let response = (await this.Get("/api/account/sendCompletionEmail/")) as any;
    };
    /*public downloadDocument = async () => {
        let response = (await this.Get("/api/covid/createfromurl/" + this.getValue("displayName"), {
            responseType: "blob",
        })) as any;
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "qrdocument.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
    };*/

    public isFieldValid(fieldName: keyof FieldType<ResponsesModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (this.pageIndex == 0) {
            if (fieldName === "keyReasonForChoosingActivity" && value === "") {
                errorMessage = "Please enter your response.";
                isValid = errorMessage === "";
            }
        } else if (this.pageIndex == 1) {
            if (fieldName === "mostRelevantPartInRelation" && value === "") {
                errorMessage = "Please enter your response.";
                isValid = errorMessage === "";
            }
            if (fieldName === "keyTakeawaysOrIdeasForPractice" && value === "") {
                errorMessage = "Please enter your response.";
                isValid = errorMessage === "";
            }
        } else if (this.pageIndex == 2) {
            if (fieldName === "howCouldYouBuildOnYourLearning" && value === "") {
                errorMessage = "Please enter your response.";
                isValid = errorMessage === "";
            }
            if (fieldName === "whatCouldYouShare" && value === "") {
                errorMessage = "Please enter your response.";
                isValid = errorMessage === "";
            }
            if (fieldName === "significantLearningPoints" && value === "") {
                errorMessage = "Please enter your response.";
                isValid = errorMessage === "";
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        if (!isValid) {
            console.log(fieldName + " is invalid");
        }
        return isValid;
    }

    //public afterUpdate: undefined;
    afterUpdate(fieldName: keyof FieldType<ResponsesModel>, value: any): void {
        //Do something like call an api or whatever/
        //Can use this instead of mobx reaction
        return;
    }
    //public beforeUpdate: undefined;
    beforeUpdate(fieldName: keyof FieldType<ResponsesModel>, value: any): any {
        //Do something and return the new value if you want or just use it to perform another action
        return value;
    }
}
