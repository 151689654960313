import React, { useEffect, useState } from "react";
import { Box, BoxProps, useMediaQuery } from "@material-ui/core";
import { StoresInstance } from "Custom/Stores";
import { useObserver } from "mobx-react-lite";
import { autorun } from "mobx";
import { LogViewerViewModel } from "../ViewModels/LogViewerViewModel";
import { AutoGridViewModel } from "../ViewModels/AutoGridViewModel";

//type AutoGridProps = React.ComponentType<BoxProps> & {
type AutoGridProps = {
    id?: string;
    height?: string | number;
    columns?: string;
    mobileColumns?: string;
    tabletColumns?: string;
    desktopColumns?: string;
    mobileRows?: string;
    tabletRows?: string;
    desktopRows?: string;
    mobileArea?: string[];
    tabletArea?: string[];
    desktopArea?: string[];
    columnGap?: string | number;
    rowGap?: string | number;
    children?: React.ReactNode;
    paddingTop?: string | number;
    paddingBottom?: string | number;
    paddingLeft?: string | number;
    paddingRight?: string | number;
    padding?: string | number;
    justifyContent?: "normal" | "start" | "end" | "flex-start" | "flex-end" | "left" | "right" | "stretch" | "center" | "space-between" | "space-around" | "space-evenly";
    justifyItems?: "normal" | "start" | "end" | "flex-start" | "flex-end" | "left" | "right" | "stretch" | "center" | "space-between" | "space-around" | "space-evenly";
    justifySelf?: "normal" | "start" | "end" | "flex-start" | "flex-end" | "left" | "right" | "stretch" | "center" | "space-between" | "space-around" | "space-evenly";
    alignContent?: "normal" | "start" | "end" | "flex-start" | "flex-end" | "left" | "right" | "stretch" | "center" | "space-between" | "space-around" | "space-evenly";
    alignItems?: "normal" | "start" | "end" | "flex-start" | "flex-end" | "left" | "right" | "stretch" | "center" | "space-between" | "space-around" | "space-evenly";
} & React.HTMLAttributes<HTMLDivElement>;
export const AutoGrid: React.FC<AutoGridProps> = (props: AutoGridProps) => {
    const [viewModel] = useState(() => new AutoGridViewModel());
    const [columns, setColumns] = React.useState(props.desktopColumns);
    const [rows, setRows] = React.useState(props.desktopRows);
    const [area, setArea] = React.useState<string[]>(props.desktopArea!);
    const [view, setView] = React.useState("desktop");

    useEffect(() => {
        autorun(() => {
            const tabletArea = props.tabletArea === undefined ? props.desktopArea : props.tabletArea;
            const tabletColumns = props.tabletColumns === undefined ? props.desktopColumns : props.tabletColumns;

            if (StoresInstance.coreStore.isMobile) {
                setColumns(props.mobileColumns!);
                setRows(props.mobileRows!);
                setArea(props.mobileArea!);
                setView("mobile");
            } else if (StoresInstance.coreStore.isTablet) {
                setColumns(tabletColumns!);
                setRows(props.tabletRows!);
                setArea(tabletArea!);
                setView("tablet");
            } else if (StoresInstance.coreStore.isDesktop) {
                setColumns(props.desktopColumns!);
                setRows(props.desktopRows!);
                setArea(props.desktopArea!);
                setView("desktop");
            }
        });
    }, []);

    return useObserver(() => (
        <>
            <Box
                /*style={{ rowGap: 2, columnGap: props.columnGap }}*/
                id={props.id ?? ""}
                height={props.height ?? ""}
                paddingTop={props.paddingTop ?? "0"}
                paddingBottom={props.paddingBottom ?? "0"}
                padding={props.padding ?? "0 0 0 0"}
                alignContent={props.alignContent ?? ""}
                alignItems={props.alignItems ?? ""}
                justifyContent={props.justifyContent ?? ""}
                justifyItems={props.justifyItems ?? ""}
                justifySelf={props.justifySelf ?? ""}
                display="grid"
                gridTemplateColumns={columns ?? ""}
                gridTemplateRows={rows ?? ""}
                gridRowGap={props.rowGap}
                gridColumnGap={props.columnGap}
                gridTemplateAreas={viewModel.FormatAreas(area)}
            >
                {props.children}
            </Box>
        </>
    ));
};
AutoGrid.defaultProps = {
    columnGap: "1em",
    rowGap: "1em",
    paddingTop: 0,
    paddingBottom: 0,
    //paddingTop: "0",
    mobileColumns: "1fr",
    //tabletColumns: "1fr 1fr",
    //desktopColumns: "1fr 1fr 1fr 1fr",
    justifyContent: "normal",
    alignContent: "normal",
};
