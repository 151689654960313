import * as MobX from "mobx";
import * as React from "react";
import * as ReactRouterDom from "react-router-dom";
import { getHistory } from "./Core/Utils/Utils";
import { Error } from "./Core/Views/Error";

interface ErrorBoundaryProps {
    hasError: boolean;
    error: string;
}
export class AppErrorBoundary extends React.Component<unknown, ErrorBoundaryProps> {
    //@MobX.observable private error: any | undefined = undefined;
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: "" };
    }
    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error.toString() };
    }
    public componentDidCatch(error: any, info: any) {
        //this.setError(error.toString());
        console.log("Error => " + error.toString());
    }

    public render() {
        // AJ: ErrorBoundaries don't catch event/promise errors... so no point handling them here...
        // AJ: https://reactjs.org/docs/error-boundaries.html#how-about-event-handlers
        if (this.state.hasError) {
            return (
                <Error error={this.state.error} />
                // return (
                // 	<div id="myModal" className="modal">
                // 		<div className="modal-content">
                // 			<span className="close">&times;</span>
                // 			<h2>App Crashed</h2>
                // 			<p>Something has gone horribly wrong.</p>
                // 			{this.state.error}
                // 		</div>
                // 	</div>
            );
            //getHistory().push("/unknownerror");
            //return <ReactRouterDom.Redirect to={"/unknownerror"} />;
        }

        return this.props.children;
    }

    //@MobX.action private setError = (error: any) => (this.error = error);
}
