import React from "react";
import { useStyles } from "../../Custom/StylesAppSpecific/HomeStyling";
import { PaperMiddle, GridContainer } from "../Components/Styles";
import { Box, Paper, Typography } from "@material-ui/core";
import { CSSGrid, Cell } from "Core/Components/Styles";
import styled from "styled-components";
import { AutoGrid } from "../Components/AutoGrid";
import { Header } from "../../Custom/Views/Header";
import { AutoCell } from "../Components/AutoCell";

export const GridWrapper = styled(CSSGrid)`
    div {
        border: 2px solid rgb(233, 171, 88);
        border-radius: 5px;
        background-color: rgba(233, 171, 88, 0.5);
        padding: 1em;
        color: #d9480f;
    }
`;

const StyledBox = styled(Box)`
    border: 2px solid rgb(233, 171, 88);
    border-radius: 5px;
    background-color: rgba(233, 171, 88, 0.5);
    padding: 1em;
    color: #d9480f;
`;

export const GridLayouts: React.FC = () => {
    return (
        <>
            <Box height={100} width="99%" bgcolor="background.paper">
                <Paper>
                    <Typography variant="h5">Basic Grid</Typography>
                    <GridWrapper>
                        <Paper>1/12</Paper>
                        <Paper>2/12</Paper>
                        <Paper>3/12</Paper>
                        <Paper>4/12</Paper>
                        <Paper>5/12</Paper>
                        <Paper>6/12</Paper>
                        <Paper>7/12</Paper>
                        <Paper>8/12</Paper>
                        <Paper>9/12</Paper>
                        <Paper>10/12</Paper>
                        <Paper>11/12</Paper>
                        <Paper>12/12</Paper>
                    </GridWrapper>
                </Paper>
                <br />
                <Paper>
                    <Typography variant="h5">Responsive Grid</Typography>
                    <GridWrapper columns="repeat(auto-fit,minmax(400px,1fr))">
                        <Paper>A</Paper>
                        <Paper>B</Paper>
                        <Paper>C</Paper>
                        <Paper>D</Paper>
                    </GridWrapper>
                </Paper>
                <br />
                <Paper>
                    <Typography variant="h5">Holy Grail Grid</Typography>
                    <GridWrapper columns={"100px 1fr 100px"} rows={"minmax(45px,auto) 1fr minmax(45px,auto)"}>
                        <Cell width={3}>
                            <h1>Header</h1>
                        </Cell>
                        <Cell>Menu</Cell>
                        <Cell>Content</Cell>
                        <Cell>Ads</Cell>
                        <Cell width={3}>Footer</Cell>
                    </GridWrapper>
                </Paper>
                <br />
                <Paper>
                    <Typography variant="h5">Grid Areas</Typography>
                    <GridWrapper
                        columns={"100px 1fr 100px"}
                        rows={"5fr 5fr 5fr"}
                        /* prettier-ignore */
                        areas={[
							"header header  header",
							"menu   content ads   ",
							"footer footer  footer"
						]}
                    >
                        <Cell area="header">Header</Cell>
                        <Cell area="content">Content</Cell>
                        <Cell area="menu">Menu</Cell>
                        <Cell area="ads">Ads</Cell>
                        <Cell area="footer">Footer</Cell>
                    </GridWrapper>
                </Paper>
                <br />
                <Paper>
                    <Typography variant="h5">Sub Grid</Typography>
                    <GridWrapper columns={"1fr 1fr 1fr 1fr"} gap={"10px"}>
                        <GridWrapper columns={"1fr"} gap={"10px"} rows={"minmax(20px, auto)"}>
                            <div>1</div>
                            <div>2</div>
                            <div>3</div>
                            <div>4</div>
                        </GridWrapper>
                        <div>Two</div>
                        <div>Three</div>
                        <div>Four</div>
                    </GridWrapper>
                </Paper>

                <Paper>
                    <Typography variant={"h5"}>Auto Grid</Typography>
                    <AutoGrid mobileColumns={"1fr"} desktopColumns={"1fr 1fr"}>
                        <StyledBox>Column 1</StyledBox>
                        <StyledBox>Column 2</StyledBox>
                        <StyledBox>Column 3</StyledBox>
                        <StyledBox>Column 4</StyledBox>
                    </AutoGrid>
                </Paper>

                <Paper>
                    <Typography variant={"h5"}>Auto Grid With Areas</Typography>
                    <AutoGrid mobileArea={["top", "middle", "bottom"]} desktopArea={["top", "middle", "bottom"]}>
                        <AutoCell mobileArea={"bottom"} desktopArea={"top"}>
                            <StyledBox>Top</StyledBox>
                        </AutoCell>
                        <AutoCell mobileArea={"middle"} desktopArea={"middle"}>
                            <StyledBox>Middle</StyledBox>
                        </AutoCell>
                        <AutoCell mobileArea={"top"} desktopArea={"bottom"}>
                            <StyledBox>Bottom</StyledBox>
                        </AutoCell>
                    </AutoGrid>
                </Paper>
                <br />
                <br />
                <br />
                <br />
                <br />
            </Box>
        </>
    );
};
