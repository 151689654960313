import { Box, Button, Fade, Paper, Typography, Link } from "@material-ui/core";
import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Centered, StaticBGImg } from "Core/Components/Styles";
import { EditableCopyViewModel } from "../ViewModels/EditableCopyViewModel";
import { EditableInput } from "Core/Components/EditableInput";
import { ForgotPasswordModel } from "../Models";
import { ForgotPasswordViewModel } from "../ViewModels/ForgotPasswordViewModel";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "Core/Utils";
import { AutoGrid } from "../../Core/Components/AutoGrid";
import mainLogoAdmin from "../Content/mainLogoImageAdmin.svg";
import mainLogo from "../Content/mainLogoImage.svg";
import Image34 from "../Content/Image 34.jpg";
import styled from "styled-components";
import { BoxMiddle, Cell, TypographyX } from "Core/Components/Styles";

const ForgotButton = styled(Button)`
    color: white;
    background-color: red;
    font-size: 18px;
    width: 300px;
    margin-top: 20px;
`;

export const ForgotPasswordPage = () => {
    const [viewModel] = useState(() => new ForgotPasswordViewModel());
    const [viewModelEditableCopy] = useState(() => new EditableCopyViewModel());
    const { history } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const doSubmit = async (e: any) => {
        e.preventDefault();
        if (viewModel.isModelValid()) {
            setIsLoading(true);
            const apiResult: any = await viewModel.ForgotPasswordAsync();
            setIsLoading(false);
            if (apiResult !== null) {
                if (apiResult.wasSuccessful) {
                    history.push(`/forgotpassword-success`);
                } else {
                    if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                        setErrorMessage("* " + apiResult.errors[0].message);
                    } else {
                        setErrorMessage("* Unknown error has occurred.");
                    }
                }
            }
        }
    };

    const goToForgotPassword = () => {
        history.push("/forgotpassword");
    };

    return useObserver(() => (
        <Fade in timeout={1000}>
            <>
                {/* <Box marginLeft={viewModel.isMobile ? "0" : "15%"} width="300px" display="flex" justifyContent="center">
                    <AutoGrid desktopColumns="1fr" tabletColumns="1fr">
                        {<img src={mainLogo} alt="" className="mainLogo" />}
                        <StaticBGImg alt={""} src={Image34} />
                        <AutoGrid>
                            <Box justifyContent="center" textAlign="center" display="grid" minWidth="300px" maxWidth="300px">
                                <Typography component="h1" variant="h5" style={{ marginBottom: "25px", marginTop: "25px" }}>
                                    {ReactHtmlParser(viewModelEditableCopy.getEditableCopyByRouteAndPlaceholderNumber("/forgotpassword", 1))}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {ReactHtmlParser(viewModelEditableCopy.getEditableCopyByRouteAndPlaceholderNumber("/forgotpassword", 2))}
                                </Typography>
                            </Box>
                            <form onSubmit={doSubmit}>
                                {errorMessage !== "" && (
                                    <Typography variant="caption" style={{ color: "red" }}>
                                        {errorMessage}
                                    </Typography>
                                )}
                                <EditableInput<ForgotPasswordModel> type="email" label="Email Address" validateOnBlur={true} viewModel={viewModel} fieldName="email" />
                                <ForgotButton type="submit" fullWidth variant="contained" color="primary" disabled={isLoading}>
                                    {isLoading ? "Please Wait..." : "Forgot password"}
                                </ForgotButton>
                            </form>
                        </AutoGrid>
                    </AutoGrid>
                </Box> */}
                <StaticBGImg alt={""} src={Image34} />
                <Box position="absolute" bottom="0" width="100%">
                    <AutoGrid desktopColumns="1fr">
                        <Box marginLeft="10%">
                            <TypographyX fontSize="44px" customcolor="white">
                                CPD learning tool
                            </TypographyX>
                        </Box>
                        <Box padding="20px 20px 100px 0" style={{ backgroundColor: "white" }} height="100%" width="100%">
                            <Box marginLeft="10%" width="375px">
                                <TypographyX>Forgotten password</TypographyX>
                                <EditableInput<ForgotPasswordModel> type="email" label="Email Address" validateOnBlur={true} viewModel={viewModel} fieldName="email" />
                                <Link data-cy="forgot-password" style={{ color: "blue" }} onClick={() => goToForgotPassword()}>
                                    Forgot Password?
                                </Link>
                                <Box>
                                    <ForgotButton type="submit" fullWidth variant="contained" color="primary" disabled={isLoading}>
                                        {isLoading ? "Please Wait..." : "Send reset link"}
                                    </ForgotButton>
                                </Box>
                            </Box>
                        </Box>
                    </AutoGrid>
                </Box>
            </>
        </Fade>
    ));
};
