import { action, computed, runInAction } from "mobx";
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { LoginModel, User } from "../Models";
import { AccountStatus, ApiResult } from "Core/Models";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { TestModel } from "../../Core/Models/TestModel";
import { AdminHomeModel } from "../Models/AdminHomeModel";
import * as MobX from "mobx";
import { StoresInstance } from "../Stores";

export class AdminHomeViewModel extends ViewModelBase<AdminHomeModel> {
    @MobX.observable public pendingUsers: User[] = [];
    @MobX.observable public approvedUsers: User[] = [];

    constructor() {
        super(new AdminHomeModel());
        console.log("AdminHome View Model Created");
        //EN - Havent figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(AdminHomeModel);
        //(window as any).model = this.model;
        //(window as any).proxy = proxy;
    }

    public downloadCert = async (id: string) => {
        let response = (await this.Get("/api/account/downloadcertificatebyuserid/" + id, { responseType: "blob" })) as any;
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CPD_Certificate_Plygene.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
    };

    public approveUser = async (user: User): Promise<ApiResult<string>> => {
        let apiResult = await this.Post<string>("/api/user/approveuser", user);
        if (apiResult.wasSuccessful) {
            runInAction(() => {
                user.approved = true;
            });
        }
        return apiResult;
    };

    public deleteUser = async (user: User): Promise<ApiResult<string>> => {
        let apiResult = await this.Post<string>("/api/user/deleteuser", user);
        if (apiResult.wasSuccessful) {
            runInAction(() => {
                user.deleted = true;
            });
        }
        return apiResult;
    };

    public sendForgotPassword = async (user: User): Promise<ApiResult<string>> => {
        let apiResult = await this.Post<string>("/api/user/sendforgotpassword", user);
        if (apiResult.wasSuccessful) {
            runInAction(() => {
                user.approved = true;
            });
        }
        return apiResult;
    };

    @action
    public getPendingUsers = async (): Promise<ApiResult<User[]>> => {
        let apiResult = await this.Get<User[]>("/api/user/getpendingusers");

        runInAction(() => {
            this.pendingUsers = [];
            apiResult.payload.forEach((item, index) => {
                let user = new User(item.id);
                user.fromDto(item);
                this.pendingUsers.push(user);
            });
        });

        return apiResult;
    };

    public getApprovedUsers = async (): Promise<ApiResult<User[]>> => {
        let apiResult = await this.Get<User[]>("/api/user/getapprovedusers");

        runInAction(() => {
            this.approvedUsers = [];
            apiResult.payload.forEach((item, index) => {
                let user = new User(item.id);
                user.fromDto(item);
                this.approvedUsers.push(user);
            });
        });

        return apiResult;
    };

    public isFieldValid(fieldName: keyof FieldType<AdminHomeModel>, value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        return isValid;
    }

    //public afterUpdate: undefined;
    afterUpdate(fieldName: keyof FieldType<LoginModel>, value: any): void {
        //Do something like call an api or whatever/
        //Can use this instead of mobx reaction
        return;
    }
    //public beforeUpdate: undefined;
    beforeUpdate(fieldName: keyof FieldType<LoginModel>, value: any): any {
        //Do something and return the new value if you want or just use it to perform another action
        if (value === "something" && fieldName === "email") {
            value = "boo";
        }
        return value;
    }
}
