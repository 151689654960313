import { useStyles } from "../../Custom/StylesAppSpecific/HomeStyling";
import { PaperMiddle, GridContainer } from "../Components/Styles";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const Container = styled(Paper)`
    display: flex;
    margin-bottom: 10px;
`;

const FlexRow = styled(Box)`
    background-color: royalblue;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export interface FlexColumnProps {
    size: number;
}
const FlexColumn = styled(Box)<FlexColumnProps>`
    color: white;
    border: 0.2rem solid white;
    width: ${(props) => (props.size / 12) * 100}vw;
    justify-content: center;
    align-items: center;
`;

export const Layouts: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <Box height={100} width="99%" bgcolor="background.paper">
                <Typography variant="h5">Basic Grids</Typography>
                <FlexRow>
                    <FlexColumn size={2} height="100px" bgcolor="red">
                        <span>Left</span>
                    </FlexColumn>
                    <FlexColumn size={8} height="100px" bgcolor="green">
                        Middle
                    </FlexColumn>
                    <FlexColumn size={2} height="100px" bgcolor="blue">
                        Right
                    </FlexColumn>
                </FlexRow>
                <FlexRow>
                    <Box bgcolor="red" height="100px" width="250px" flexGrow={0}>
                        Fixed
                    </Box>
                    <Box bgcolor="green" height="100px" flexGrow={1}>
                        Stretch
                    </Box>
                    <Box bgcolor="blue" height="100px" width="250px" flexGrow={0}>
                        Fixed
                    </Box>
                </FlexRow>
            </Box>
        </>
    );
};
