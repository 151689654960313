import React, { useContext } from "react";
import { Stores, StoresContext } from "./Custom/Stores";

import { Admin } from "./Custom/Views/Admin/Admin";
import { ForgotPasswordPage } from "./Custom/Views/ForgotPasswordPage";
import { ForgotPasswordSuccessPage } from "./Custom/Views/ForgotPasswordSuccessPage";
import { Samples } from "./Custom/Views/Samples";
import { LogViewer } from "./Core/Views/LogViewer";
import { LoginPage } from "./Custom/Views/LoginPage";
import { PrivateRoute } from "Core/Utils";
import { RegisterPage } from "./Custom/Views/RegisterPage";

import { ResetPasswordPage } from "./Custom/Views/ResetPasswordPage";
import { RoleDetails } from "./Custom/Views/Admin/RoleDetails";
import { SessionExpired } from "./Custom/Views/SessionExpired";
import { TestPage } from "./Core/Views/TestPage";
import { User } from "./Custom/Views/User";
import { UserDetails } from "./Custom/Views/Admin/UserDetails";
import { Logout } from "Custom/Views/Logout";
import { Error } from "./Core/Views/Error";
import { Error2 } from "./Core/Views/Error2";
import { NotFound } from "./Core/Views/NotFound";
import { AdminRoutes } from "./Custom/Views/Admin/AdminRoutes";
import { PrivacyPolicy } from "./Custom/Views/PrivacyPolicy";
import { TermsAndConditions } from "./Custom/Views/TermsAndConditions";
import { Home } from "./Custom/Views/Home";
import { CertificatePage } from "./Custom/Views/CertificatePage";
import { LayoutNoHeader } from "./Custom/Views/Layouts/LayoutNoHeader";
import { DefaultLayout } from "./Custom/Views/Layouts/DefaultLayout";
import { RouteWithLayout as Route } from "./Core/Components/RouteWithLayout";
import { ThankYouForRegistering } from "./Custom/Views/ThankYouForRegistering";

export const AppRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            {/*
            I have added a component that will now accept a different layout per route.
            This line
                import { RouteWithLayout as Route } from "./Core/Components/RouteWithLayout";
            renames the usual route to use the new RouteWithLayout component. The defayult layout is called DefaultLayout in the Layout folder
            */}
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={() => <LoginPage admin={false} />} />
            <Route exact path="/adminlogin" component={() => <LoginPage admin={true} />} />
            <Route exact path="/samples" component={Samples} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/forgotpassword" component={ForgotPasswordPage} />
            <Route exact path="/forgotpassword-success" component={ForgotPasswordSuccessPage} />
            <Route exact path="/resetpassword" component={ResetPasswordPage} />
            <Route exact path="/register" component={RegisterPage} />
            <Route exact path="/rewardreg" component={RegisterPage} />
            <Route exact path="/sessionexpired" component={SessionExpired} />
            <Route exact path="/logviewer" component={LogViewer} />
            <Route exact path="/error" component={Error} />
            <Route exact path="/notfound" component={NotFound} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/terms" component={TermsAndConditions} />
            <Route exact path="/test" component={TestPage} />
            <Route exact path="/certificate" component={CertificatePage} />
            <Route exact path="/thankyou" component={ThankYouForRegistering} />

            <AdminRoutes />
        </>
    );
};
