import React, { useState } from "react";
import { UnsupportedBrowser } from "./UnsupportedBrowser";
import { Stores, StoresInstance } from "../Stores";
import { AdminHome } from "./Admin/AdminHome";
import { CertificatePage } from "./CertificatePage";
import { LoginPage } from "./LoginPage";
import { ThankYouForRegistering } from "./ThankYouForRegistering";

export const Home: React.FC = () => {
    const getHomePage = () => {
        if (StoresInstance.domain.AccountStore.isInRole("admin")) {
            return <AdminHome />;
        } else if (StoresInstance.domain.AccountStore.Approved) {
            return <>{(window as any).IE11 ? <UnsupportedBrowser /> : <>{StoresInstance.domain.AccountStore.IsLoggedIn ? <CertificatePage /> : <LoginPage admin={false} />}</>}</>;
        } else if (!StoresInstance.domain.AccountStore.Approved) {
            return (
                <>
                    {(window as any).IE11 ? (
                        <UnsupportedBrowser />
                    ) : (
                        <>{StoresInstance.domain.AccountStore.IsLoggedIn ? <ThankYouForRegistering /> : <LoginPage admin={false} />}</>
                    )}
                </>
            );
        } else {
            return <>{(window as any).IE11 ? <UnsupportedBrowser /> : <LoginPage admin={false} />}</>;
        }
    };

    return <>{getHomePage()}</>;
};
