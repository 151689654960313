import { Box, Button, Fade, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Centered, StaticBGImg } from "Core/Components/Styles";
import { EditableCopyViewModel } from "../ViewModels/EditableCopyViewModel";
import { useObserver } from "mobx-react-lite";
import { AutoGrid } from "../../Core/Components/AutoGrid";
import mainLogo from "../Content/mainLogoImage.svg";
import Image34 from "../Content/Image 34.jpg";
import { EditableInput } from "../../Core/Components/EditableInput";
import { ForgotPasswordModel } from "../Models";

export const ForgotPasswordSuccessPage: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new EditableCopyViewModel());

    return useObserver(() => (
        <Fade in timeout={1000}>
            <>
                <Box marginLeft={viewModel.isMobile ? "0" : "15%"} width="400px" display="flex" justifyContent="center">
                    <AutoGrid desktopColumns="1fr" tabletColumns="1fr">
                        {<img src={mainLogo} alt="" className="mainLogo" />}
                        <StaticBGImg alt={""} src={Image34} />
                        <AutoGrid>
                            <Box padding="5px">
                                <Box justifyContent="center" textAlign="center" display="grid" minWidth="300px" maxWidth="400px">
                                    <Typography component="h1" variant="h5" style={{ marginBottom: "25px", marginTop: "25px" }}>
                                        Thank you, please check your
                                        <br />
                                        inbox for an email from us
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        with your password reset link
                                    </Typography>
                                </Box>
                            </Box>
                        </AutoGrid>
                    </AutoGrid>
                </Box>
            </>
        </Fade>
        /*<Centered>
            <Fade in timeout={1000}>
                <Paper>
                    <Box padding="5px">
                        <Box justifyContent="center" textAlign="center" display="grid" minWidth="300px" maxWidth="300px">
                            <Typography component="h1" variant="h3" style={{ marginBottom: "25px", marginTop: "25px" }}>
                                {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/forgotpassword-success", 1))}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/forgotpassword-success", 2))}
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </Fade>
        </Centered>*/
    ));
};
