/* eslint-disable @typescript-eslint/no-parameter-properties */
import { observable, runInAction } from "mobx";
import { defaultTheme } from "../../Custom/StylesAppSpecific/AppStyling";
import * as GoogleAnalytics from "../Utils/App/GoogleAnalyticsUtils";
import { FormLockConcurrencyHubStore } from "./FormLockConcurrencyHubStore";
import { Stores } from "../../Custom/Stores";
import { InitialState } from "../../Custom/Models";
import * as MobX from "mobx";
import { EventArgs } from "../Utils/App/GoogleAnalyticsUtils";

export class CoreStore {
    @observable public screenWidth = 0;
    @observable public isMobile = false;
    @observable public isTablet = false;
    @observable public isDesktop = false;
    public FormLockConcurrencyHubStore = new FormLockConcurrencyHubStore();

    public constructor() {}

    public Init = (stores: Stores, initialState: InitialState) => {
        //GoogleAnalytics.Init("UA-1852243-71");
        window.addEventListener("resize", () => {
            this.setWidth();
        });
        this.setWidth();
        this.FormLockConcurrencyHubStore.init(stores, initialState);

        //this.domain.History = history;
        // EN: For debugging
        (window as any).Stores = stores;
        (window as any).MobX = MobX;
    };

    private setWidth = () => {
        runInAction(() => {
            this.screenWidth = document.body.clientWidth;
            this.isMobile = this.screenWidth <= defaultTheme.breakpoints.values["md"];
            this.isTablet = this.screenWidth > defaultTheme.breakpoints.values["md"] && this.screenWidth <= defaultTheme.breakpoints.values["lg"];
            this.isDesktop = this.screenWidth > defaultTheme.breakpoints.values["lg"];
            // document.body.classList.remove("mobile");
            // document.body.classList.remove("tablet");
            // document.body.classList.remove("desktop");
            // if (this.isMobile) {
            // 	document.body.classList.add("mobile");
            // 	document.body.classList.remove("tablet");
            // 	document.body.classList.remove("desktop");
            // }
            // if (this.isTablet) {
            // 	document.body.classList.add("tablet");
            // 	document.body.classList.remove("mobile");
            // 	document.body.classList.remove("desktop");
            // }
            // if (this.isDesktop) {
            // 	document.body.classList.add("desktop");
            // 	document.body.classList.remove("mobile");
            // 	document.body.classList.remove("tablet");
            // }
        });
        //console.log(`Screen Mobile=${this.isMobile}, Tablet=${this.isTablet},Desktop=${this.isDesktop}`);
    };

    public PageView = (path: string, title: string = "") => {
        GoogleAnalytics.pageview(path, "");
    };

    public SendEvent = (args: EventArgs) => {
        GoogleAnalytics.sendEvent(args);
    };
}
