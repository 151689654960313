import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: "none",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    datePicker: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #E0DAF0",
        height: "54px",
        marginRight: "5px",
        marginBottom: "0px",
        marginTop: "0px",
        width: "100%",
        placeholder: {
            color: "#000000",
        },
    },
    datePickerBox: {},
    datePickersGridContainer: {
        justifyContent: "space-between",
    },
    datePickerGridContainer: {
        maxWidth: "96%",
    },
    subTitle: {
        marginBottom: "18px",
        paddingBottom: "0px !important",
    },
}));
