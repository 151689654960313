import React, { useContext } from "react";
import { Stores, StoresContext } from "../../Stores";
import { UserDetails } from "./UserDetails";
import { RoleDetails } from "./RoleDetails";
import { TestPage } from "../../../Core/Views/TestPage";
import { PrivateRoute } from "../../../Core/Utils/React";
import { User } from "../../Models";
import { Admin } from "./Admin";
import { Route } from "react-router-dom";

export const AdminRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                exact
                path="/user"
                component={User}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
                }}
            />
            <PrivateRoute
                exact
                path="/admin"
                component={Admin}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
                }}
            />
            <PrivateRoute
                exact
                path="/roleslist/role/:id"
                component={RoleDetails}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
                }}
            />
            <PrivateRoute
                exact
                path="/userslist/user/:id"
                component={UserDetails}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && store.domain.AccountStore.isInRole("admin");
                }}
            />
        </>
    );
};
