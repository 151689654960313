import * as Models from "../Models";
import { action, computed, observable, runInAction } from "mobx";
import * as MobX from "mobx";
import { AccountStore } from "./Domain";
import { EditableCopy } from "../Models";
import { EditableCopyRoute } from "../Models";
import { RoleStore } from "./Domain/Admin";
import { Stores } from "./Stores";
import { UserStore } from "./Domain";
import { ResponsesModel } from "../Models/ResponsesModel";
import { ResponsesViewModel } from "../ViewModels/ResponsesViewModel";

export class DomainStores {
    @observable public openSnackBar = false;
    @observable public openErrorDialog = false;
    @observable public errorDialogContent: string = "";
    @observable public snackBarContent: string = "";
    @observable public editableCopies = MobX.observable<EditableCopy>([]);
    @observable public editableCopyRoutes = MobX.observable<EditableCopyRoute>([]);
    //public ResponsesViewModel = new ResponsesViewModel();
    public AccountStore = new AccountStore();
    public UserStore = new UserStore();
    public RoleStore = new RoleStore();
    public UserDetailId: string = "";

    public init(stores: Stores, initialState: Models.InitialState) {
        this.editableCopies.clear();
        this.editableCopyRoutes.clear();
        initialState.editableCopies.forEach((editableCopy) => {
            this.editableCopies.push(editableCopy);
        });
        initialState.editableCopyRoutes.forEach((editableCopyRoute) => {
            this.editableCopyRoutes.push(editableCopyRoute);
        });
        this.AccountStore.init(stores, initialState);
    }

    @action
    public ShowSnackBar(content: string) {
        this.snackBarContent = content;
        this.openSnackBar = true;
    }

    @action
    public CloseSnackBar() {
        this.openSnackBar = false;
    }

    @action
    public ShowErrorDialog(content: string) {
        this.errorDialogContent = content;
        this.openErrorDialog = true;
    }
}
