import { FormControl, Grid, InputLabel } from "@material-ui/core";
import PasswordField from "material-ui-password-field";
import React from "react";

export const TextInputs: React.FC = () => {
    return (
        <Grid>
            <FormControl margin="normal">
                <InputLabel htmlFor="password">Password With Show</InputLabel>
                <PasswordField placeholder="Password" autoComplete="current-password" />
            </FormControl>
        </Grid>
    );
};
