// Libraries
import React, { useContext, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { EditableCopyViewModel } from "../ViewModels/EditableCopyViewModel";
import { useMediaQuery } from "../../Core/Utils/Media";
import { Link } from "@material-ui/core";
import styled from "styled-components";

// Styling & images
import { GridSpaced } from "../StylesAppSpecific/GridHelperStyling";

export const PrivacyPolicy: React.FC = () => {
    window.scrollTo(0, 0);
    const isMobile: boolean = useMediaQuery("screen and (max-width: 767px)") ? true : false;
    const [viewModel] = useState(() => new EditableCopyViewModel());

    const privacy = (
        <>
            <GridSpaced
                container
                justify={"center"}
                margin={"0px auto"}
                padding={isMobile ? "0px 0px 40px 0px" : "0px 0px 70px 0px"}
                additionalcss={isMobile ? "max-width: 335px; width: 100%;" : "max-width: 1124px; width: 100%;"}
            >
                <TermsContainer item xs={12} sm={12}>
                    <p>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/privacy-policy", 1))}</p>
                    {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/privacy-policy", 2))}
                </TermsContainer>
            </GridSpaced>
        </>
    );

    return (
        <>
            <div>{privacy}</div>
        </>
    );
};

const TermsContainer = styled(GridSpaced)`
    p {
        font-size: 14px;
        margin-bottom: 15px;
        letter-spacing: -0.35px;
        line-height: 24px;
    }

    h1 {
        text-align: left;
        font-size: 28px;
    }

    h2 {
        text-align: left;
        font-size: 24px;
    }
`;
