import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsEmail, IsNotEmpty } from "class-validator";
import { IObservable } from "mobx";

export class AdminHomeModel extends ModelBase<AdminHomeModel, AdminHomeModel> {
    fromDto(model: AdminHomeModel): void {}

    toDto(model: AdminHomeModel): void {}
}
