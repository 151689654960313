import { ForgotPasswordModel, ForgotPasswordModelDTO } from "../Models";
import { action, runInAction } from "mobx";

import { ApiResult } from "../../Core/Models/ApiResult";
import { Server } from "Custom/Globals/AppUrls";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";

export class ForgotPasswordViewModel extends ViewModelBase<ForgotPasswordModel> {
    constructor() {
        super(new ForgotPasswordModel());
        this.setDecorators(ForgotPasswordModel);
    }

    @action
    public ForgotPasswordAsync = async (): Promise<ApiResult<ForgotPasswordModelDTO>> => {
        return await this.Post<ForgotPasswordModelDTO>(Server.Api.Account.ForgotPassword, this.model);
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: "email", value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }
}
