import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import styled from "styled-components";
import { EditableInput } from "../../Core/Components/EditableInput";
import { RegisterUserViewModel } from "../ViewModels/RegisterUserViewModel";
import { getHistory, nameofFactory } from "../../Core/Utils/Utils";
import { RegisterUserModel } from "../Models/RegisterUserModel";
import { KeyValuePair } from "../../Core/Models/KeyValuePair";
import { AutoGrid } from "Core/Components/AutoGrid";
import { TypographyX } from "Core/Components/Styles";
import regImage from "Custom/Content/regImage.svg";
import { StaticBGImg } from "Core/Components/Styles";
import { StoresInstance } from "../Stores";
import { Break } from "Custom/StylesAppSpecific/AppStyling";
import { useObserver } from "mobx-react-lite";
import { useLocation } from "react-router";

const Main = styled.main`
    width: 1250px;
    align-items: center;
    margin-left: 5px;
    margin-right: auto;
`;

const MainGrid = styled.section``;
const RegButton = styled(Button)`
    color: white;
    font-size: 18px;
    width: 282px;
`;
const SaveButton = styled(Button)`
    color: white;
    background-color: red;
    font-size: 18px;
    width: 282px;
`;
interface Props {
    id?: string;
}
export const RegisterPage: React.FC<Props> = ({ id }: Props) => {
    const [viewModel] = useState(() => new RegisterUserViewModel());
    const bind = viewModel.getContext();
    let isFreeGiftPage = false;
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/rewardreg") {
            if (StoresInstance.domain.AccountStore.IsLoggedIn) {
                viewModel.checkIfUserHasRequestedGift();
            }
            viewModel.setIsFreeGift(true);
        } else if (!StoresInstance.domain.AccountStore.isInRole("admin") && StoresInstance.domain.AccountStore.IsLoggedIn) {
            viewModel.history.push("/certifcate");
        }
        if (id && id.length > 0 && StoresInstance.domain.AccountStore.IsLoggedIn) {
            let _ = viewModel.getUserDetail(id);
        }
    }, []);

    const doSubmit = async (e: any) => {
        e.preventDefault();
        //isValid will check all fields to make sure they are in a valid state.
        if (!viewModel.isModelValid()) {
            viewModel.setErrorMessage("Please fill out the fields marker in red below.");
        } else {
            await viewModel.register();
        }
    };

    const getSection = () => {
        return useObserver(() => (
            <Box id={"registerpage"} marginLeft="15%" marginRight="15%" style={{ display: viewModel.userAlreadyRequestedGift ? "none" : "block" }}>
                <Box>
                    <StaticBGImg alt={""} src={regImage} />
                    <Box padding="5px">
                        <Box paddingTop={1} paddingBottom={1}>
                            {!viewModel.isFreeGift ? (
                                <TypographyX variant="h6" fontWeight={700} paddingBottom="28px">
                                    Register your account
                                </TypographyX>
                            ) : (
                                <>
                                    <TypographyX variant="h6" fontWeight={700} paddingBottom="10px">
                                        Register your free gift
                                    </TypographyX>
                                    <TypographyX variant="body2" paddingBottom="28px">
                                        To thank you for completing the Sharmans CPD we would like to send you a gift.
                                    </TypographyX>
                                </>
                            )}
                        </Box>
                        <form onSubmit={doSubmit}>
                            {viewModel.errorMessage !== "" && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {viewModel.errorMessage}
                                </Typography>
                            )}
                            <MainGrid>
                                <AutoGrid desktopColumns="1fr 1fr 1fr 1fr">
                                    <EditableInput<RegisterUserModel> shrink={true} viewModel={viewModel} label="First Name" fieldName="firstName" />
                                    <EditableInput<RegisterUserModel> shrink={true} viewModel={viewModel} label="Last Name" fieldName="lastName" />
                                    <EditableInput<RegisterUserModel> shrink={true} type={"tel"} viewModel={viewModel} label="Contact number" fieldName="phoneNumber" />
                                    <EditableInput<RegisterUserModel> shrink={true} viewModel={viewModel} type="email" label="Contact email" fieldName="email" />
                                </AutoGrid>
                                <Break />
                                <AutoGrid desktopColumns="2fr 1fr 1fr">
                                    <EditableInput<RegisterUserModel> shrink={true} viewModel={viewModel} label="Company name" fieldName={"companyName"} />
                                </AutoGrid>
                                <Break />
                                <AutoGrid desktopColumns="1fr 1fr 1fr 1fr">
                                    <EditableInput<RegisterUserModel> shrink={true} viewModel={viewModel} label="Address line 1" fieldName={"address1"} />
                                    <EditableInput<RegisterUserModel> shrink={true} viewModel={viewModel} label="Address line 2" fieldName={"address2"} />
                                    <EditableInput<RegisterUserModel> shrink={true} viewModel={viewModel} label="City\Town" fieldName={"city"} />
                                    <EditableInput<RegisterUserModel> shrink={true} viewModel={viewModel} label="Postcode" fieldName={"postCode"} />
                                </AutoGrid>
                                <Break />
                                {StoresInstance.domain.UserDetailId.length === 0 && !viewModel.isFreeGift && (
                                    <>
                                        <AutoGrid desktopColumns="1fr 1fr 1fr 1fr">
                                            <EditableInput<RegisterUserModel>
                                                type={"password"}
                                                noFill={true}
                                                shrink={true}
                                                viewModel={viewModel}
                                                label="Password"
                                                fieldName={"password"}
                                            />
                                            <EditableInput<RegisterUserModel>
                                                type={"password"}
                                                noFill={true}
                                                shrink={true}
                                                viewModel={viewModel}
                                                label="Confirm Password"
                                                fieldName={"confirmPassword"}
                                            />
                                        </AutoGrid>
                                        <Break />
                                    </>
                                )}
                                {viewModel.isFreeGift && (
                                    <>
                                        <EditableInput<RegisterUserModel> type={"checkbox"} viewModel={viewModel} fieldName={"sendGift"} label={"Please send me my free gift"} />
                                        <Break />
                                        {!viewModel.rewardRegistered && (
                                            <Box style={{ paddingTop: "20px" }} textAlign="center">
                                                <RegButton type="submit" fullWidth={false} variant="contained" color="secondary" size="large">
                                                    {viewModel.IsLoading ? "Please wait..." : "Submit"}
                                                </RegButton>
                                            </Box>
                                        )}
                                        {viewModel.rewardRegistered && <Typography variant={"h6"}>Thank you! You will hear from us shortly</Typography>}
                                    </>
                                )}
                                {!viewModel.isFreeGift && (
                                    <Box style={{ paddingTop: "20px" }} textAlign="center">
                                        {StoresInstance.domain.UserDetailId === "" ? (
                                            <RegButton type="submit" fullWidth={false} variant="contained" color="primary" size="large">
                                                {viewModel.IsLoading ? "Please wait..." : "Register"}
                                            </RegButton>
                                        ) : (
                                            <SaveButton
                                                onClick={() => {
                                                    viewModel.saveChanges();
                                                }}
                                                fullWidth={false}
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                            >
                                                {viewModel.IsLoading ? "Please wait..." : "Save Changes"}
                                            </SaveButton>
                                        )}
                                    </Box>
                                )}
                            </MainGrid>
                        </form>
                    </Box>
                </Box>
                {StoresInstance.domain.UserDetailId.length > 0 && (
                    <>
                        <Box>
                            <Typography variant={"h5"}>Before the activity</Typography>
                            <Typography variant={"h6"}>What was the key reason for you choosing to undertake this activity?</Typography>
                            <Box>{viewModel.responsesViewModel.getValue("keyReasonForChoosingActivity")}</Box>
                        </Box>
                        <br />
                        <Box>
                            <Typography variant={"h5"}>During the activity</Typography>
                            <Typography variant={"h6"}>Were there any key takeaways or ideas for practice you thought of?</Typography>
                            <Box>{viewModel.responsesViewModel.getValue("keyTakeawaysOrIdeasForPractice")}</Box>
                            <Typography variant={"h6"}>What was the most relevant part in relation to your role?</Typography>
                            <Box>{viewModel.responsesViewModel.getValue("mostRelevantPartInRelation")}</Box>
                        </Box>
                        <br />
                        <Box>
                            <Typography variant={"h5"}>After the activity</Typography>
                            <Typography variant={"h6"}>How could you build on your learning?</Typography>
                            <Box>{viewModel.responsesViewModel.getValue("howCouldYouBuildOnYourLearning")}</Box>
                            <Typography variant={"h6"}>What could you share with your colleagues?</Typography>
                            <Box>{viewModel.responsesViewModel.getValue("whatCouldYouShare")}</Box>
                            <Typography variant={"h6"}>Significant learning points to transfer to a CPD record if required:</Typography>
                            <Box>{viewModel.responsesViewModel.getValue("significantLearningPoints")}</Box>
                        </Box>
                        <br />
                        <Box style={{ paddingTop: "20px" }} textAlign="center">
                            <RegButton
                                onClick={() => {
                                    viewModel.downloadCert();
                                }}
                                fullWidth={false}
                                variant="contained"
                                color="primary"
                                size="large"
                            >
                                {viewModel.IsLoading ? "Please wait..." : "Download Certificate"}
                            </RegButton>
                        </Box>
                    </>
                )}
            </Box>
        ));
    };

    return useObserver(() => (
        <>
            {getSection()}
            {viewModel.userAlreadyRequestedGift && (
                <Box id={"registerpage"} marginLeft="15%" marginRight="15%">
                    <Typography variant={"h5"}>You have already requested your gift</Typography>
                </Box>
            )}
        </>
    ));
};
