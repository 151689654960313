import { useStyles } from "Custom/StylesAppSpecific/HomeStyling";
import { Box, Button, Typography } from "@material-ui/core";
import React from "react";

export const Buttons: React.FC = () => {
    const classes = useStyles();
    return (
        <>
            <>
                <Typography>
                    Lots more buttons here can be found here{" "}
                    <a href="https://material-ui.com/components/buttons/" target="_blank">
                        Material UI Buttons
                    </a>
                </Typography>
                <Box>
                    <Button variant="contained" className={classes.button}>
                        Default
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button}>
                        Primary
                    </Button>
                    <Button variant="contained" color="secondary" className={classes.button}>
                        Secondary
                    </Button>
                    <Button variant="contained" color="secondary" disabled className={classes.button}>
                        Disabled
                    </Button>
                    <Button variant="contained" href="#contained-buttons" className={classes.button}>
                        Link
                    </Button>
                    <input accept="image/*" className={classes.input} id="contained-button-file" multiple type="file" />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" component="span" className={classes.button}>
                            Upload
                        </Button>
                    </label>
                </Box>
            </>
        </>
    );
};
