import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsEmail, IsNotEmpty } from "class-validator";
import { IObservable } from "mobx";

export class LoginModel extends ModelBase<LoginModel, LoginModelDTO> {
    @MobX.observable
    @IsNotEmpty({ message: "You must enter an email address" })
    @IsEmail({}, { message: "Email address is not in a valid format" })
    public email: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "You must enter a password" })
    public password: string = "";

    @MobX.observable
    public rememberMe: boolean = false;

    fromDto(model: LoginModelDTO): void {}

    toDto(model: LoginModel): void {}
}

export interface LoginModelDTO {
    email: string;
    password: string;
    rememberMe: boolean;
}
