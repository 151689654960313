import { coreTheme } from "./../../Core/Components/Styles/CoreStyling";
import { createMuiTheme, Theme, ThemeOptions } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { colors } from "@material-ui/core";
import { Palette } from "@material-ui/core/styles/createPalette";
import styled from "styled-components";

//Define your custom breakpoints here or accept the default values which are the standard common breakpoints
let customBreakpointValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};
export const customBreakpoints = createBreakpoints({ values: customBreakpointValues });

export const Break = styled.div`
    border-bottom: 1px solid grey;
    padding-top: 5px;
    padding-bottom: 15px;
`;

//Add your specific app styling here
declare module "@material-ui/core" {
    export interface Theme {
        app: {
            colors: {
                success: {
                    dark: string;
                    light: string;
                };
            };
        };
    }
}

const getTheme = () => {
    let theme = createMuiTheme(coreTheme as ThemeOptions);
    return theme;
};

const appTheme = {
    app: {
        typography: {
            fontFamily: `"Nexa", Arial`,
        },
        colors: {
            success: {
                dark: colors.green[600],
                light: colors.green[300],
            },
            blue: {
                dark: "#337AB7",
            },
        },
    },
    overrides: {
        // Typography: {
        //     MuiButton: {
        //         textTransform: "none",
        //     },
        // },
        MuiFormHelperText: {
            root: {
                color: "black",
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "white",
            },
        },
        MuiInputBase: {
            input: {
                paddingLeft: "10px",
                backgroundColor: "white",
                borderRadius: "3px",
                border: "1px solid #ced4da",
                borderBottom: "0",
            },
        },
        MuiLink: {
            root: {
                color: "#005FAB",
                textDecoration: "underline!important",
            },
        },
        MuiButton: {
            label: {
                fontSize: "small",
                // textTransform: "lowercase",
            },
        },
        MuiTypography: {
            root: {
                fontSize: "smaller",
            },
        },
        // MuiBox: {
        //     root: {
        //         paddingLeft: "241px",
        //     },
        // },
        /*MuiFormLabel: {
            root: {
                color: "black",
            },
        },*/
    },
};

//Overrides

let customPalette = {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#fafafa" },
    primary: { main: "#61aa66", light: "#92dc94", dark: "#317a3a" },
    secondary: { main: "#c70101", dark: "#8e0000", light: "#ff4e30", contrastText: "#fff" },
    error: { light: "#e57373", main: "#f44336", dark: "#d32f2f", contrastText: "#fff" },
    text: {
        primary: "rgba(0,0,0, 1)",
        secondary: "rgba(0,0,0, 0.8)",
        disabled: "rgba(0,0,0, 0.38)",
        hint: "rgba(0,0,0, 0.38)",
    },
} as Palette;

let theme = getTheme();
theme.breakpoints = customBreakpoints;
theme.palette = { ...theme.palette, ...customPalette };
theme = { ...theme, ...appTheme };

export const defaultTheme = theme;
//(window as any).theme = defaultTheme;
