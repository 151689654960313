import * as MobX from "mobx";
import { InitialState, LoginModel } from "../Models";
import { AccountStatus, ApiResult } from "Core/Models";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { runInAction } from "mobx";
import { StoresInstance } from "../Stores";

export class InitViewModel extends ViewModelBase<any> {
    @MobX.observable
    public DataReady: boolean = false;
    public InitData: InitialState = {} as InitialState;
    constructor() {
        super({});
    }

    public getInitialState = async (): Promise<boolean> => {
        let apiResult = await this.Get<InitialState>("/api/initialstate/getinitialstatejson");
        runInAction(() => {
            // if (!apiResult.payload.accountStatus.isLoggedIn) {
            // 	StoresInstance.domain.AccountStore.Logout(false);
            // }
            StoresInstance.init(apiResult.payload);
            this.InitData = apiResult.payload;
            this.DataReady = true;
        });
        return apiResult.wasSuccessful;
    };

    public isFieldValid(fieldName: "email" | "password", value: any): boolean {
        return true;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
