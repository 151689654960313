// App
import * as Errors from "../../Errors";

export const HttpStatusToError: (httpStatusCode: number, httpStatusText: string) => {} = (httpStatusCode, httpStatusText) => {
    if (httpStatusCode === 401) {
        return new Errors.HttpUnauthorizedError();
    } else if (httpStatusCode === 403) {
        return new Errors.HttpForbiddenError();
    } else {
        return new Errors.HttpError(httpStatusCode, httpStatusText);
    }
};
