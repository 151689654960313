import { ModelBase } from "Core/Models/ModelBase";
import * as MobX from "mobx";
import { validate, validateOrReject, Contains, IsInt, Length, IsEmail, IsFQDN, IsDate, Min, Max, IsNotEmpty, ValidateIf, Equals, MinLength } from "class-validator";
import { RegisterAddressModel } from "./RegisterUserAddressModel";

export class RegisterUserModel extends ModelBase<RegisterUserModel, RegisterUserModelDTO> {
    @MobX.observable
    @IsNotEmpty({ message: "Last name is required" })
    public lastName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "First name is required" })
    public firstName: string = "";

    @MobX.observable
    public age: number | string = "";

    @MobX.observable
    public areaOfInterest: number | string = "";

    @MobX.observable
    public publications: number | string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Email address is required" })
    @IsEmail({}, { message: "Email address is not correct" })
    public email: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Password is required" })
    @MinLength(6, { message: "Password must be 6 characters or more" })
    public password: string = "";

    @MobX.observable
    public sendGift: boolean = false;

    @MobX.observable
    //@IsNotEmpty({ message: "Email address is required" })
    //@ValidateIf(that => that.password !== that.confirmPassword)
    //@Length(1, 0, { message: "password !== passwordConfirmation" })
    public confirmPassword: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Contact number is required" })
    public phoneNumber: string = "";

    @MobX.observable
    /*@Equals(true, { message: "Must be ticked" })*/
    public terms: boolean = false;

    /* @MobX.observable
    @IsNotEmpty({ message: "Gender is required" })
    public gender: string = "";*/
    @MobX.observable
    //@IsNotEmpty({ message: "Please select an option" })
    public updatedWithProducts: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Please select an option" })
    public undertakeDilapidation: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Please select an option" })
    public failingGutters: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Please select an option" })
    public systemUseful: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Please select an option" })
    public allowConditionReport: string = "";

    @MobX.observable
    public buildingSurveyingJournal: boolean = false;

    @MobX.observable
    public architectsDataFile: boolean = false;

    @MobX.observable
    public riba: boolean = false;

    @MobX.observable
    public roofing: boolean = false;

    @MobX.observable
    public companyName: string = "";

    @MobX.observable
    public companyNumber: string = "";

    @MobX.observable
    public companyEmail: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "First line of address is required" })
    public address1: string = "";

    @MobX.observable
    public address2: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "CityTown is required" })
    public city: string = "";

    @MobX.observable
    public county: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Postcode is required" })
    public postCode: string = "";

    @MobX.observable
    public saveAddress: boolean = false;

    @MobX.observable
    public dob: Date = new Date();

    @MobX.observable
    public tod: Date = new Date();

    public fromDto = (model: RegisterUserModelDTO) => {};
    public toDto = (model: RegisterUserModel) => {};
}

export interface RegisterUserModelDTO {
    firstName: string;
    lastName: string;
    age: number | string;
    email: string;
    password: string;
    confirmPassword: string;
    address1: string;
    address2: string;
    city: string;
    county: string;
    postCode: string;
    saveAddress: boolean;
    terms: boolean;
}
